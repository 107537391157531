import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./components/navigation.jsx";
import BlogPage from "./components/BlogPage";
import HeroSection from "./components/HeroSection.jsx";
import GraphLocationsdata from "./components/GraphLocationsdata.jsx";
import Instruments from "./components/Instruments.jsx";
import GalleryPreview from "./components/GalleryPreview.jsx";
import FooterComponent from "./components/FooterComponent.jsx";
import JsonData from "./data/data.json";
import Team from "./components/Team.jsx";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import TeamSection from "./components/TeamSection.jsx";
import GallerySection from "./components/GallerySection.jsx";
import InfoCards from "./components/InfoCards";
import Overview from "./components/Overview.jsx";
import Awareness from "./components/Awareness.jsx";
import ContactForm from "./components/Contact.jsx";
// import blogs from "./data/blogData";
import Statistics from "./components/Statistics.jsx";
import TrendAnalysis from "./components/TrendAnalysis/TrendAnalysis.jsx"; // Import TrendAnalysis
// In index.js or App.js
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


// Forms imports
import CinemaHallObservationForm from "./components/Forms/CinemaHallObservationForm.jsx";
import MallForm from "./components/Forms/MallForm.jsx";
import OfficeBuilding from "./components/Forms/OfficeBuilding.jsx";
import QuestionnaireAdult from "./components/Forms/QuestionnaireAdult.jsx";
import QuestionnaireMid from "./components/Forms/QuestionnaireMid.jsx";
import QuestionnaireSmall from "./components/Forms/QuestionnaireSmall.jsx";
import ResidentialQues from "./components/Forms/ResidentialQues.jsx";
import RestaurantObservationForm from "./components/Forms/RestaurantObservationForm.jsx";
import SchoolQues from "./components/Forms/SchoolQues.jsx";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

const Home = ({ landingPageData }) => {
  return (
    <div className="complete-body">
      <section id="hero" className="full-screen-section-hero">
        <HeroSection data={landingPageData.HeroSection} />
      </section>

      <section
        id="info-cards"
        className="full-screen-section info-cards-section"
      >
        <div className="info-card-container">
          <InfoCards />
        </div>
      </section>

      <section id="gallery" className="full-screen-section gallery-section">
        <div className="gallery-preview-container">
          <GalleryPreview />
        </div>
      </section>
      <section id="graphs" className="full-screen-section gallery-section">
        <div className="gallery-preview-container">
        <GraphLocationsdata data={landingPageData.About} />

        </div>
      </section>

      <section id="team" className="full-screen-section team-section">
        <div className="team-section-container">
          <TeamSection />
        </div>
      </section>

     

      <FooterComponent data={landingPageData.Contact} />
    </div>
  );
};

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [activeTab, setActiveTab] = useState("ParameterWise");

  const [region, setRegion] = useState("");
  const [season, setSeason] = useState("");
  const [activeAnalysisTab, setActiveAnalysisTab] = useState(null);

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
      <div>
        <Navigation
          setRegion={setRegion}
          setSeason={setSeason}
          setActiveAnalysisTab={setActiveAnalysisTab}
        />
        <Routes>
          <Route
            path="/"
            element={<Home landingPageData={landingPageData} />}
          />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/gallery" element={<GallerySection />} />
          <Route path="/team" element={<Team />} />
          <Route path="/instruments" element={<Instruments />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/awareness" element={<Awareness />} />
          <Route path="/footer" element={<FooterComponent />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/statistics" element={<Statistics />} />

          <Route
            path="/trendanalysis"
            element={
              <TrendAnalysis
                region={region}
                season={season}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            }
          />
          {/* Forms */}
          <Route path="/questionnaire/mid" element={<QuestionnaireMid />} />
          <Route path="/questionnaire/small" element={<QuestionnaireSmall />} />
          <Route path="/questionnaire/adult" element={<QuestionnaireAdult />} />
          <Route
            path="/questionnaire/cinema"
            element={<CinemaHallObservationForm />}
          />
          <Route path="/questionnaire/mall" element={<MallForm />} />
          <Route path="/questionnaire/office" element={<OfficeBuilding />} />
          <Route
            path="/questionnaire/residential"
            element={<ResidentialQues />}
          />
          <Route
            path="/questionnaire/restaurant"
            element={<RestaurantObservationForm />}
          />
          <Route path="/questionnaire/school" element={<SchoolQues />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
