import React from "react";
import { FaArrowLeft } from "react-icons/fa"; 
import { useNavigate } from "react-router-dom"; 
import standeeImage from '../images/awareness/iaq_staNDEE.jpg'; 
import "../css/Awareness.css"; 

const Awareness = () => {
  const navigate = useNavigate(); 

  // Navigate back to the previous page
  const goBack = () => {
    navigate(-1); 
  };

  return (
    <div className="standee-container">
      {/* Header Section */}
      {/* <div className="standee-header">
        <button className="back-button" onClick={goBack}>
          <FaArrowLeft className="back-icon" />
          <span>Back</span>
        </button>
        <h1 className="standee-title">IAQ Standee</h1>
      </div> */}

      {/* Image Display Section */}
      <div className="image-container">
        <img src={standeeImage} alt="IAQ Standee" className="standee-image" />
      </div>
    </div>
  );
};

export default Awareness;
