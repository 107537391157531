import React, { useState, useEffect } from "react";
import "../css/HeroSection.css";
import video from "../images/video.mp4";

const HeroSection = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="hero-container">
      <div className={`hero-overlay ${isScrolled ? "zoom-out" : ""}`}>
        {/* <h1>SAFE IAQ</h1>
        <p>Clean Air, Healthy Life</p> */}
      </div>
      <video autoPlay loop muted className={`hero-video ${isScrolled ? "fade-in" : ""}`}>
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};

export default HeroSection;
