import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/Team.css";

// Importing member images (only first 5 for this section)
import member1 from '../images/teams/1.jpg';
import member2 from '../images/teams/2.png';
import member3 from '../images/teams/3.jpg';
import member4 from '../images/teams/4.jpg';
import member5 from '../images/teams/5.jpg';
import member6 from '../images/teams/6.jpg';

// List of team members (first 5 for this section)
const teamMembers = [
  { img: member1, name: 'Ajay Ojha' },
  { img: member2, name: 'Prasad Pawar' },
  { img: member3, name: 'Harshada Sawant' },
  { img: member4, name: 'Sonalee Borse' },
  { img: member5, name: 'Bishnupriya Tudu' },
  { img: member6, name: 'Shruti Mahale' },
];

const TeamSection = () => {
  const navigate = useNavigate();

  return (
    <div id="team-section" className="team-section">
      <h2>Meet Our Team</h2>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <img
              src={member.img}
              alt={`Team Member ${index + 1}`}
              className="team-image"
            />
            <h3>{member.name}</h3>
          </div>
        ))}
      </div>
      <button
        className="view-all-btn"
        onClick={() => navigate("/team")}
      >
        View All Members
      </button>   
    </div>
  );
};

export default TeamSection;
