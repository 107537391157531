import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaPen } from "react-icons/fa";
import "../css/Contactmsg.css";

const Contact = () => {
  const [msgData, setmsgData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setmsgData({
      ...msgData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!msgData.name || !msgData.email || !msgData.message) {
      toast.error("All fields are required!");
      setLoading(false);
      return;
    }

    try {
      // const response = await fetch("http://localhost:8081/api/contact", {
        const response = await fetch("http://localhost:8081/api/contact", {

        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(msgData),
      });

      if (response.ok) {
        toast.success("Message sent successfully!");
        setmsgData({ name: "", email: "", message: "" });
      } else {
        const errorData = await response.text();
        toast.error(errorData || "Failed to send the message.");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-wrapper">
      <div className="contact-card">
        <div className="contact-info">
          <h2>Contact Us</h2>
          <p>We'd love to hear from you! Get in touch using the msg or the contact details below.</p>
          <div className="info-item">
            <FaMapMarkerAlt className="icon" />
            <p>Ekta Society,101,202, Hem Opal, Plot No. 26, Wakadewadi, Pune, Maharashtra 411005</p>
          </div>
          <div className="info-item">
            <FaPhoneAlt className="icon" />
            <p>020-29996577</p>
          </div>
          <div className="info-item">
            <FaEnvelope className="icon" />
            <p>research@techknowgreen.com</p>
          </div>
        </div>
        <div className="contact-msg-batttt">
          <h2>Send Us a Message</h2>
          <form onSubmit={handleSubmit} className="contact-msg"> {/* Change <msg> to <form> */}
            <div className="msg-group">
              {/* <FaUser className="input-icon" /> */}
              <input
                type="text"
                name="name"
                value={msgData.name}
                onChange={handleChange}
                placeholder="Your Name"
                required
              />
            </div>
            <div className="msg-group">
              <FaEnvelope className="input-icon" />
              <input
                type="email"
                name="email"
                value={msgData.email}
                onChange={handleChange}
                placeholder="Your Email"
                required
              />
            </div>
            <div className="msg-group">
              <FaPen className="input-icon" />
              <textarea
                name="message"
                value={msgData.message}
                onChange={handleChange}
                placeholder="Your Message"
                required
              />
            </div>
            <button type="submit" disabled={loading}>
              {loading ? "Sending..." : "Send Message"}
            </button>
          </form>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default Contact;
