import React, { useState } from 'react';
import '../../css/common.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const QuestionnaireAdult = () => {
  const [formData, setFormData] = useState({
    locationId: '',
    participantId: '',
    placeOfDataCollection: '',
    todaysDate: '',
    dateOfParticipation: '',
    subjectInitials: '',
    dateOfBirth: '',
    age: '',
    locationCategory: '',
    gender: '',
    educationalQualifications: '',
    occupationStatus: '',
    occupationName: '',
    otherOccupation: '',
    typeOfResidence: '',
    residenceDetails: '',
    distanceFromMainRoad: '',
    hasSeparateKitchen: false,
    numberOfWindowsInKitchen: '',
    smokingStatus: '',
    cigarettesBidisPerDay: '',
    smokingYears: '',
    lpgUsage: false,
    lpgUsageYears: '',
    keroseneUsage: false,
    keroseneUsageYears: '',
    biomassUsage: false,
    biomassUsageYears: '',
    electricHeaterUsage: false,
    electricHeaterUsageYears: '',
    highBloodPressureCurrent: false,
    highBloodPressureCurrentYears: '',
    highBloodPressurePast: false,
    highBloodPressurePastYears: '',
    heartDiseaseCurrent: false,
    heartDiseaseCurrentYears: '',
    heartDiseasePast: false,
    heartDiseasePastYears: '',
    diabetesCurrent: false,
    diabetesCurrentYears: '',
    otherDisease: '',
    otherDiseaseName: '',
    otherDiseaseDuration: '',
    diabetesPast: false,
    diabetesPastYears: '',
    asthmaCurrent: false,
    asthmaCurrentYears: '',
    asthmaPast: false,
    asthmaPastYears: '',
    copdCurrent: false,
    copdCurrentYears: '',
    copdPast: false,
    copdPastYears: '',
    strokeParalysisCurrent: false,
    strokeParalysisCurrentYears: '',
    strokeParalysisPast: false,
    strokeParalysisPastYears: '',
    tbCurrent: false,
    tbCurrentYears: '',
    tbPast: false,
    tbPastYears: '',
    otherDiagnosis: '',
    otherDiagnosisYears: '',
    childCurrentSymptomsCough: false,
    childCurrentSymptomsShortnessOfBreath: false,
    childCurrentSymptomsPhlegm: false,
    childCurrentSymptomsChestTightness: false,
    childCurrentSymptomsWheeze: false,
    childCurrentSymptomsHeadache: false,
    childCurrentSymptomsAllergicSkinConditions: false,
    childCurrentSymptomsAllergicRunnyNose: false,
    childPastSymptomsCough: false,
    childPastSymptomsShortnessOfBreath: false,
    childPastSymptomsPhlegm: false,
    childPastSymptomsChestTightness: false,
    childPastSymptomsWheeze: false,
    childPastSymptomsHeadache: false,
    childPastSymptomsAllergicSkinConditions: false,
    childPastSymptomsAllergicRunnyNose: false,
    mosquitoMattFrequency: '',
    mosquitoCoilFrequency: '',
    mosquitoLiquidVaporizerFrequency: '',
    dhoopStickFrequency: '',
    agarbattiFrequency: '',
    diyaFrequency: '',
    candleFrequency: '',
    keroseneLampFrequency: '',
    roomFreshenerFrequency: '',
    heightCm: null,
    weightKg: null,
    bloodPressureSys: null,
    bloodPressureDiaSto: null,
    pulseBpm: null,
    spO2Percent: null,
    peakFlowMeterReading1: null,
    peakFlowMeterReading2: null,
    peakFlowMeterReading3: null,
    lungOscillometry: '',
    lungOscillometryReason: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post('http://89.116.20.162:8081/adult/participants/add', {
        // const response = await axios.post('http://localhost:8081/adult/participants/add', {

        ...formData,
      });
  
      if (response && response.data) {
        toast.success('Form submitted successfully', {
          autoClose: 2000,
          // onClose: () => {
          //   window.location.reload();
          // },
        });
      } else {
        console.error('Error: Response or response data is undefined');
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error('Error:', error.response.data || error.response.status);
      } else if (error.request) {
        // Request was made but no response was received
        console.error('No response received:', error.request);
      } else {
        // Something happened in setting up the request
        console.error('Error in request setup:', error.message);
      }
    }
  };
  

  return (
    <>
      <ToastContainer /> {/* Added this to display toast notifications */}
      <form onSubmit={handleSubmit}>
        <center style={{ marginTop: "80px" }}>  <h1>Health Questionnaire For Adults</h1></center>
      
        <label>
          Location Id:
          <input
            type="text"
            name="locationId"
            value={formData.locationId}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Participant Id:
          <input
            type="text"
            name="participantId"
            value={formData.participantId}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Place of Data Collection:
          <input
            type="text"
            name="placeOfDataCollection"
            value={formData.placeOfDataCollection}
            onChange={handleChange}
            required
          />
        </label>

        {/* <label>
    Todays Date:
    <input
      type="date"
      name="todaysDate"
      value={formData.todaysDate}
      onChange={handleChange}
      required
    />
  </label> */}
        <label>
          Date of Participation:
          <input
            type="date"
            name="dateOfParticipation"
            value={formData.dateOfParticipation}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Participant Name:
          <input
            type="text"
            name="subjectInitials"
            value={formData.subjectInitials}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Date of Birth:
          <input
            type="date"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Age:
          <input
            type="number"
            name="age"
            value={formData.age}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Location Category:
          <select
            name="locationCategory"
            value={formData.locationCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Schools">Schools</option>
            <option value="Residencies">Residencies</option>
            <option value="Malls_Shopping_Complexes">Malls/Shopping Complexes</option>
            <option value="Cinema_halls">Cinema halls</option>
            <option value="Offices">Offices</option>
            <option value="Restaurants">Restaurants</option>
            <option value="Eateries">Eateries</option>
          </select>
        </label>

        <label>
          Gender:
          <select
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Others">Others</option>
            <option value="Do not want to disclose">Do not want to disclose</option>
          </select>
        </label>

        <label>
          Educational Qualifications:
          <select
            name="educationalQualifications"
            value={formData.educationalQualifications}
            onChange={handleChange}
            required
          >
            <option value="">Select Educational Qualifications</option>
            <option value="Not_Educated">Not Educated</option>
            <option value="Primary_School">Primary School</option>
            <option value="Secondary_School">Secondary School</option>
            <option value="Junior_college_Bachelors_Diploma">Junior college Bachelors Diploma</option>
            <option value="Masters_and_above">Masters and above</option>
          </select>
        </label>


        <label>
          Occupation Status:
          <select
            name="occupationStatus"
            value={formData.occupationStatus}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Employed">Employed</option>
            <option value="Unemployed">Unemployed</option>
            <option value="Retired">Retired</option>
          </select>
        </label>

        {(formData.occupationStatus === 'Employed' || formData.occupationStatus === 'Retired') &&
          <div>
            <label>
              Occupation Name:
              <select
                name="occupationName"
                value={formData.occupationName}
                onChange={handleChange}
                required
              >
                <option value="">Select Occupation</option>
                <option value="Welding">Welding</option>
                <option value="Farming">Farming</option>
                <option value="Driver (Rickshaw/Taxi/Bus/Truck)">Driver (Rickshaw/Taxi/Bus/Truck)</option>
                <option value="Industry (Paint, auto works, chemical)">Industry (Paint, auto works, chemical)</option>
                <option value="Shop / Business (on/near to the road)">Shop / Business (on/near to the road)</option>
                <option value="Office based">Office based</option>
                <option value="Others">Others</option>
              </select>
            </label>
            {formData.occupationName === 'Others' &&
              <div>
                <label>
                  Other Occupation:
                  <input
                    type="text"
                    name="otherOccupation"
                    value={formData.otherOccupation}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
            }
          </div>
        }

        <label>
          Type of Residence:
          <select
            name="typeOfResidence"
            value={formData.typeOfResidence}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Kaccha">Kaccha</option>
            <option value="Pakka">Pakka</option>
          </select>
        </label>


        {formData.typeOfResidence === 'Pakka' &&
          <div>
            <label>
              Residence Details:
              <select
                name="residenceDetails"
                value={formData.residenceDetails}
                onChange={handleChange}
                required
              >
                <option value="">Select Residence Type</option>
                <option value="Flat">Flat</option>
                <option value="Independent house">Independent house</option>
              </select>
            </label>
          </div>
        }


        <label>
          Distance from Main Road (in meters):
          <select
            name="distanceFromMainRoad"
            value={formData.distanceFromMainRoad}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="<100mts">&lt;100mts</option>
            <option value="100-200mts">100-200mts</option>
            <option value="200-500mts">200-500mts</option>
            <option value=">500mts">&gt;500mts</option>
          </select>
        </label>


        <label>
          Has Separate Kitchen:
          <select
            name="hasSeparateKitchen"
            value={formData.hasSeparateKitchen}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>


        {formData.hasSeparateKitchen &&
          <label>
            Number of Windows in the Kitchen:
            <select
              name="numberOfWindowsInKitchen"
              value={formData.numberOfWindowsInKitchen}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">≥3</option>
              <option value="Open Air cooking">Open Air cooking</option>
            </select>
          </label>
        }

        <label>
          Smoking Status:
          <select
            name="smokingStatus"
            value={formData.smokingStatus}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Current">Current</option>
            <option value="Ex">Ex</option>
            <option value="Never">Never</option>
          </select>
        </label>

        {(formData.smokingStatus === 'Current' || formData.smokingStatus === 'Ex') &&
          <>
            <label>
              Cigarettes or Bidis per Day:
              <input
                type="number"
                name="cigarettesBidisPerDay"
                value={formData.cigarettesBidisPerDay}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Smoking Years:
              <input
                type="number"
                name="smokingYears"
                value={formData.smokingYears}
                onChange={handleChange}
                required
              />
            </label>
          </>
        }

        <label>
          LPG Usage:
          <select
            name="lpgUsage"
            value={formData.lpgUsage}
            onChange={handleChange}
            required
          >
            <option value="">Select LPG Usage</option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </label>

        {formData.lpgUsage &&
          <label>
            LPG Usage Years:
            <input
              type="number"
              name="lpgUsageYears"
              value={formData.lpgUsageYears}
              onChange={handleChange}
              required
            />
          </label>
        }


        <label>
          Kerosene Usage:
          <select
            name="keroseneUsage"
            value={formData.keroseneUsage}
            onChange={handleChange}
            required
          >
            <option value="">Select Kerosene Usage</option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </label>

        {formData.keroseneUsage &&
          <label>
            Kerosene Usage Years:
            <input
              type="number"
              name="keroseneUsageYears"
              value={formData.keroseneUsageYears}
              onChange={handleChange}
              required
            />
          </label>
        }

        <label>
          Biomass Usage:
          <select
            name="biomassUsage"
            value={formData.biomassUsage}
            onChange={handleChange}
            required
          >
            <option value="">Select Biomass Usage</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.biomassUsage &&
          <label>
            Biomass Usage Years:
            <input
              type="number"
              name="biomassUsageYears"
              value={formData.biomassUsageYears}
              onChange={handleChange}
              required
            />
          </label>
        }
        <label>
          Electric Heater Usage:
          <select
            name="electricHeaterUsage"
            value={formData.electricHeaterUsage}
            onChange={handleChange}
            required
          >
            <option value="">Select Electric Heater Usage</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </label>

        {formData.electricHeaterUsage === 'true' && (
          <label>
            Electric Heater Usage Years:
            <input
              type="number"
              name="electricHeaterUsageYears"
              value={formData.electricHeaterUsageYears}
              onChange={handleChange}
              required
            />
          </label>
        )}


        <label>
          Mosquito Matt Frequency:
          <select
            name="mosquitoMattFrequency"
            value={formData.mosquitoMattFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Mosquito Coil Frequency:
          <select
            name="mosquitoCoilFrequency"
            value={formData.mosquitoCoilFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Mosquito Liquid Vaporizer Frequency:
          <select
            name="mosquitoLiquidVaporizerFrequency"
            value={formData.mosquitoLiquidVaporizerFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Dhoop Stick Frequency:
          <select
            name="dhoopStickFrequency"
            value={formData.dhoopStickFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Agarbatti Frequency:
          <select
            name="agarbattiFrequency"
            value={formData.agarbattiFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Diya Frequency:
          <select
            name="diyaFrequency"
            value={formData.diyaFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Candle Frequency:
          <select
            name="candleFrequency"
            value={formData.candleFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Kerosene Lamp Frequency:
          <select
            name="keroseneLampFrequency"
            value={formData.keroseneLampFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
            required
          </select>
        </label>

        <label>
          Room Freshener Frequency:
          <select
            name="roomFreshenerFrequency"
            value={formData.roomFreshenerFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>
        {/* Repeat similar pattern for Heart Disease, Diabetes, Asthma, COPD, Stroke/Paralysis, Tuberculosis */}
        {/* Here's an example for Heart Disease */}
        <h3>Are you currently diagnosed with the following ?</h3>
        {/* High Blood Pressure (Current) */}
        <label>
          High Blood Pressure:
          <select
            name="highBloodPressureCurrent"
            value={formData.highBloodPressureCurrent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>


        {formData.highBloodPressureCurrent === 'yes' &&
          <label>
            High Blood Pressure Years:
            <input
              type="number"
              name="highBloodPressureCurrentYears"
              value={formData.highBloodPressureCurrentYears}
              onChange={handleChange}
              required
            />
          </label>
        }

        <label>
          Heart Disease:
          <select
            name="heartDiseaseCurrent"
            value={formData.heartDiseaseCurrent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>
        {formData.heartDiseaseCurrent === 'yes' && (
          <label>
            Years:
            <input
              type="number"
              name="heartDiseaseCurrentYears"
              value={formData.heartDiseaseCurrentYears}
              onChange={handleChange}
              required
            />
          </label>
        )}


        {/* Repeat similar pattern for Diabetes, Asthma, COPD, Stroke/Paralysis, Tuberculosis */}
        {/* Continue adding similar label/input fields for all other remaining questionnaire fields */}

        {/* For example: */}
        {/* Diabetes */}
        <label>
          Diabetes:
          <select
            name="diabetesCurrent"
            value={formData.diabetesCurrent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.diabetesCurrent === 'yes' &&
          <label>
            Diabetes Years:
            <input
              type="number"
              name="diabetesCurrentYears"
              value={formData.diabetesCurrentYears}
              onChange={handleChange}
              required
            />
          </label>
        }


        {/* Asthma (Current) */}
        <label>
          Asthma:
          <select
            name="asthmaCurrent"
            value={formData.asthmaCurrent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.asthmaCurrent === 'yes' &&
          <label>
            Asthma Years:
            <input
              type="number"
              name="asthmaCurrentYears"
              value={formData.asthmaCurrentYears}
              onChange={handleChange}
              required
            />
          </label>
        }

        {/* Asthma (Past) */}


        <label>
          COPD:
          <select
            name="copdCurrent"
            value={formData.copdCurrent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.copdCurrent === 'yes' &&
          <label>
            COPD (Current) Years:
            <input
              type="number"
              name="copdCurrentYears"
              value={formData.copdCurrentYears}
              onChange={handleChange}
              required
            />
          </label>
        }

        {/* Stroke/Paralysis */}
        <label>
          Stroke/Paralysis :
          <select
            name="strokeParalysisCurrent"
            value={formData.strokeParalysisCurrent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.strokeParalysisCurrent === 'yes' &&
          <label>
            Stroke/Paralysis Years:
            <input
              type="number"
              name="strokeParalysisCurrentYears"
              value={formData.strokeParalysisCurrentYears}
              onChange={handleChange}
              required
            />
          </label>
        }
        {/* Tuberculosis */}
        <label>
          Tuberculosis (TB) :
          <select
            name="tbCurrent"
            value={formData.tbCurrent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.tbCurrent === 'yes' &&
          <label>
            Tuberculosis (TB)  Years:
            <input
              type="number"
              name="tbCurrentYears"
              value={formData.tbCurrentYears}
              onChange={handleChange}
              required
            />
          </label>
        }
        <label>
          Other Disease:
          <select
            name="otherDisease"
            value={formData.otherDisease}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.otherDisease === 'yes' && (
          <div>
            <label>
              Name of the Disease:
              <input
                type="text"
                name="otherDiseaseName"
                value={formData.otherDiseaseName}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Duration in years:
              <input
                type="number"
                name="otherDiseaseDuration"
                value={formData.otherDiseaseDuration}
                onChange={handleChange}
                required
              />
            </label>
          </div>
        )}
        <h3>Were you diagnosed with any of the following in the past ?</h3>
        <label>
          Tuberculosis (TB):
          <select
            name="tbPast"
            value={formData.tbPast}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.tbPast === 'yes' &&
          <label>
            Tuberculosis (TB) Years:
            <input
              type="number"
              name="tbPastYears"
              value={formData.tbPastYears}
              onChange={handleChange}
              required
            />
          </label>
        }
        {/* High Blood Pressure (Past) */}
        <label>
          Cancer:
          <select
            name="cancerPast"
            value={formData.cancerPast}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.cancerPast === 'yes' &&
          <label>
            Cancer Years:
            <input
              type="number"
              name="cancerYears"
              value={formData.cancerYears}
              onChange={handleChange}
              required
            />
          </label>
        }

        <label>
          Pneumonia :
          <select
            name="pneumoniaPast"
            value={formData.pneumoniaPast}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.pneumoniaPast === 'yes' &&
          <label>
            Pneumonia Years:
            <input
              type="number"
              name="pneumoniaPastYears"
              value={formData.pneumoniaPastYears}
              onChange={handleChange}
              required
            />
          </label>
        }

        {/* <label>
  Heart Disease :
  <select
    name="heartDiseasePast"
    value={formData.heartDiseasePast}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{formData.heartDiseasePast === 'yes' &&
  <label>
    Heart Disease (Past) Years:
    <input
      type="number"
      name="heartDiseasePastYears"
      value={formData.heartDiseasePastYears}
      onChange={handleChange}
      required
    />
  </label>
} */}

        {/* <label>
  Asthma :
  <select
    name="asthmaPast"
    value={formData.asthmaPast}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{formData.asthmaPast === 'yes' &&
  <label>
    Asthma Years:
    <input
      type="number"
      name="asthmaPastYears"
      value={formData.asthmaPastYears}
      onChange={handleChange}
      required
    />
  </label>
} */}
        {/* <label>
  COPD :
  <select
    name="copdPast"
    value={formData.copdPast}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{formData.copdPast === 'yes' &&
  <label>
    COPD Years:
    <input
      type="number"
      name="copdPastYears"
      value={formData.copdPastYears}
      onChange={handleChange}
      required
    />
  </label>
}
<label>
  Stroke/Paralysis :
  <select
    name="strokeParalysisPast"
    value={formData.strokeParalysisPast}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{formData.strokeParalysisPast === 'yes' &&
  <label>
    Stroke/Paralysis Years:
    <input
      type="number"
      name="strokeParalysisPastYears"
      value={formData.strokeParalysisPastYears}
      onChange={handleChange}
      required
    />
  </label>
} */}
        {/* Other Diagnosis */}
        <label>
          Other Diagnosis:
          <input
            type="text"
            name="otherDiagnosis"
            value={formData.otherDiagnosis}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Years since Other Diagnosis:
          <input
            type="number"
            name="otherDiagnosisYears"
            value={formData.otherDiagnosisYears}
            onChange={handleChange}
            required
          />
        </label>
        <h3>Are you experiencing any of the following symptoms currently ?</h3>

        {/* Child Symptoms */}
        <label>
          Cough:
          <select
            name="childCurrentSymptomsCough"
            value={formData.childCurrentSymptomsCough}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Shortness of Breath:
          <select
            name="childCurrentSymptomsShortnessOfBreath"
            value={formData.childCurrentSymptomsShortnessOfBreath}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Phlegm:
          <select
            name="childCurrentSymptomsPhlegm"
            value={formData.childCurrentSymptomsPhlegm}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Chest Tightness:
          <select
            name="childCurrentSymptomsChestTightness"
            value={formData.childCurrentSymptomsChestTightness}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Wheeze:
          <select
            name="childCurrentSymptomsWheeze"
            value={formData.childCurrentSymptomsWheeze}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Headache:
          <select
            name="childCurrentSymptomsHeadache"
            value={formData.childCurrentSymptomsHeadache}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Allergic Skin Conditions:
          <select
            name="childCurrentSymptomsAllergicSkinConditions"
            value={formData.childCurrentSymptomsAllergicSkinConditions}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Allergic Runny Nose:
          <select
            name="childCurrentSymptomsAllergicRunnyNose"
            value={formData.childCurrentSymptomsAllergicRunnyNose}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>
        <h3>Have you experienced any of the following symptoms within the past 12 months ?</h3>

        <label>
          Cough:
          <select
            name="childPastSymptomsCough"
            value={formData.childPastSymptomsCough}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>
        <label>
          Shortness of Breath:
          <select
            name="childPastSymptomsShortnessOfBreath"
            value={formData.childPastSymptomsShortnessOfBreath}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>
        <label>
          Phlegm:
          <select
            name="childPastSymptomsPhlegm"
            value={formData.childPastSymptomsPhlegm}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Chest Tightness:
          <select
            name="childPastSymptomsChestTightness"
            value={formData.childPastSymptomsChestTightness}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Wheeze:
          <select
            name="childPastSymptomsWheeze"
            value={formData.childPastSymptomsWheeze}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Headache:
          <select
            name="childPastSymptomsHeadache"
            value={formData.childPastSymptomsHeadache}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Allergic Skin Conditions:
          <select
            name="childPastSymptomsAllergicSkinConditions"
            value={formData.childPastSymptomsAllergicSkinConditions}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Allergic Runny Nose:
          <select
            name="childPastSymptomsAllergicRunnyNose"
            value={formData.childPastSymptomsAllergicRunnyNose}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>
        <h3>Clinical Examination</h3>
        <label>
          Height (cm):
          <input
            type="number"
            name="heightCm"
            value={formData.heightCm}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Weight (Kg):
          <input
            type="number"
            name="weightKg"
            value={formData.weightKg}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Blood Pressure systolic:
          <input
            type="text"
            name="bloodPressureSys"
            value={formData.bloodPressureSys}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Blood Pressure diastolic:
          <input
            type="text"
            name="bloodPressurediaSto"
            value={formData.bloodPressurediaSto}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Pulse (bpm):
          <input
            type="number"
            name="pulseBpm"
            value={formData.pulseBpm}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          SpO2 (%):
          <input
            type="number"
            name="spO2Percent"
            value={formData.spO2Percent}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Peak Flow Meter Reading 1:
          <input
            type="number"
            name="peakFlowMeterReading1"
            value={formData.peakFlowMeterReading1}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Peak Flow Meter Reading 2:
          <input
            type="number"
            name="peakFlowMeterReading2"
            value={formData.peakFlowMeterReading2}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Peak Flow Meter Reading 3:
          <input
            type="number"
            name="peakFlowMeterReading3"
            value={formData.peakFlowMeterReading3}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Lung Oscillometry:
          <select
            name="lungOscillometry"
            value={formData.lungOscillometry}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Performed">Performed</option>
            <option value="NotPerformed">Not Performed</option>
          </select>
        </label>

        {formData.lungOscillometry === 'NotPerformed' && (
          <label>
            Why Not Performed:
            <input
              type="text"
              name="lungOscillometryReason"
              value={formData.lungOscillometryReason}
              onChange={handleChange}
              required
            />
          </label>
        )}

        {/* Continue adding similar label/input fields for all other questionnaire fields */}
        {/* Add the remaining fields here */}

        <button type="submit">Submit</button>
      </form>
    </>
  );
};

export default QuestionnaireAdult;
