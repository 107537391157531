import React, { useEffect } from "react";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FiMapPin, FiPhone } from "react-icons/fi";
import AOS from "aos";
import "aos/dist/aos.css";
import "../css/FooterComponent.css";

const FooterComponent = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <footer className="footer" id="footerr">
      <div className="footer-container">
        {/* Main Flex Container for Address and Socials */}
        <div className="footer-main" data-aos="fade-up">
          {/* Address Section */}
          <div className="footer-column address">
            <h4>Locate US</h4>
            <p>
              <FiMapPin className="footer-icon" /> Ekta Society, 101, 202, Hem
              Opal, Plot No. 26, Wakadewadi, Pune, Maharashtra 411005
            </p>
            <p>
              <FiPhone className="footer-icon" /> 020-29996577
            </p>
            <a
                        href="https://www.google.com/maps/place/Techknowgreen+Solutions+Limited/@18.5412483,73.8519754,49m/data=!3m1!1e3!4m6!3m5!1s0x3bc2c1b5ee812d21:0x27b499748ae9187b!8m2!3d18.5411895!4d73.8521271!16s%2Fg%2F11l1m1d50c?authuser=0&entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="direction-link"
                    >
                        Get Directions
                    </a>
          </div>

          {/* Social Media Section */}
          <div className="footer-column follow-us">
            <h4>Follow Us</h4>
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/company/techknowgreen-solutions-limited"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <FaLinkedinIn className="social-icon" />
              </a>
              <a
                href="https://www.instagram.com/techknowgreensolutionsltd/"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <FaInstagram className="social-icon" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100090868442953"
                target="_blank"
                rel="noopener noreferrer"
                className="social-link"
              >
                <FaFacebookF className="social-icon" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="footer-bottom" data-aos="fade-up">
        <p>
          © {new Date().getFullYear()} Techknowgreen Solutions Limited. All
          Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default FooterComponent;
