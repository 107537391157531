import React, { useState } from "react";
import '../../css/common.css';
import axios from "axios";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// \\SchoolQues,CinemaHallObservationForm,OfficeBuilding,QuestionnaireAdult,MallForm,QuestionnaireMid,QuestionnaireSmall,ResidentialQues,RestaurantObservationForm
const SchoolQues = () => {
  const [formData, setFormData] = useState({
    observerName: "",
    observationDate: "",
    schoolAddress: "",
    locationId: "",
    participantId: "",
    buildingAgeCategory: "",
    schoolTotalAreaCategory: "",
    totalStudentsCategory: "",
    averageStudentsPresent: "",
    totalStaffCategory: "",
    averageStaffPresent: "",
    totalClassroomsCategory: "",
    computerLabsCategory: "",
    chemistryLabsCategory: "",
    biologyLabsCategory: "",
    physicsLabsCategory: "",
    sportsRoomCategory: "",
    canteen: "",
    canteenCookingFuel: "",
    canteenCookingTimeCategory: "",
    canteenVentilationType: "",
    schoolVentilationType: "",
    schoolWindowsCategory: "",
    hvacDuctsMaintenance: "",
    hvacNoOfVentsCategory: "",
    exhaustFanAirVelocitiesInlet: "",
    exhaustFanAirVelocitiesOutlet: "",
    cleaningActivitiesMethod: "",
    cleaningActivitiesTimesManually: "",
    cleaningActivitiesTimesEquipment: "",
    chemicalBasedCleaningProducts: "",
    cleaningProductType: "",
    teachingMaterialUsed: "",
    classroomAreaCategory: "",
    classroomStudentCountCategory: "",
    furnitureTypes: "",
    classroomTablesChairsCountCategory: "",
    classroomBenchesCountCategory: "",
    classroomFurnitureMaterial: "",
    classroomWallMaterial: "",
    classroomFloorType: "",
    classroomBlackboardTypes: "",
    classroomTeachingMaterial: "",
    classroomActivities: "",
    classroomVentilationControl: "",
    classroomMoistureDampness: "",
    classroomTempHumidityControl: "",
    classroomMaintenanceIssues: "",
    classroomLocationFloorLevel: "",
    classroomWindowCount: "",
    classroomCurtainsPresent: "",
    classroomLunchBreakStudentsPresent: "",
    classroomDistanceFromTrafficSourceCategory: "",
    classroomAirPurifiersUsed: "",
    classroomIndoorPlantsPresent: "",
    classroomIndoorPlantsCountCategory: "",
    classroomPlaygroundDistanceCategory: "",
    otherActivitiesObserved: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        // "http://localhost:8081/school/air-quality-data/add",
        "http://89.116.20.162:8081/school/air-quality-data/add",

        {
          ...formData,
        }
      );

      if (response && response.data) {
        toast.success("Form submitted successfully", {
          onClose: () => {
            window.location.reload();
          },
        });
      } else {
        console.error("Error: Response or response data is undefined");
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <>
      <ToastContainer /> {/* Add this to display toast notifications */}
      <form onSubmit={handleSubmit}>
        <center>
          <h1 style={{ marginTop: "80px" }}>School</h1>
        </center>
        <label>
          Location Id:
          <input
            type="text"
            name="locationId"
            value={formData.locationId}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Participant Id:
          <input
            type="text"
            name="participantId"
            value={formData.participantId}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Name of Observer:
          <input
            type="text"
            name="observerName"
            value={formData.observerName}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Observation Date:
          <input
            type="date"
            name="observationDate"
            value={formData.observationDate}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          School Address:
          <input
            type="text"
            name="schoolAddress"
            value={formData.schoolAddress}
            onChange={handleChange}
            required
          />
        </label>
        {/* Add other form fields here */}

        <label>
          Building age in years:
          <select
            name="buildingAgeCategory"
            value={formData.buildingAgeCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="0 to 5">0 to 5</option>
            <option value="5 to 10">5 to 10</option>
            <option value="10 to 15">10 to 15</option>
            <option value="more than 20">More than 20</option>
          </select>
        </label>
        <label>
          School Total Area:
          <select
            name="schoolTotalAreaCategory"
            value={formData.schoolTotalAreaCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="below 2000m2">Below 2000m2</option>
            <option value="2000 to 4000m2">2000 to 4000m2</option>
            <option value="4000m2 to 6000m2">4000m2 to 6000m2</option>
            <option value="6000m2 to 8000m2">6000m2 to 8000m2</option>
          </select>
        </label>
        <label>
          Total no. Of students:
          <select
            name="totalStudentsCategory"
            value={formData.totalStudentsCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="below 500">Below 500</option>
            <option value="500 to 1000">500 to 1000</option>
            <option value="1000 to 2000">1000 to 2000</option>
            <option value="2000 to 3000">2000 to 3000</option>
            <option value="3000 to 4000">3000 to 4000</option>
          </select>
        </label>
        <b>
          <h3>Total attendance of students and staff in year</h3>
        </b>
        <label>
          Average Students Present:
          <select
            name="averageStudentsPresent"
            value={formData.averageStudentsPresent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="<50">Less than 50%</option>
            <option value="50-60%">50% to 60%</option>
            <option value="60-70%">60% to 70%</option>
            <option value="70-80%">70% to 80%</option>
            <option value="80-90%">80% to 90%</option>
            <option value=">90%">Greater than 90%</option>
          </select>
        </label>

        <label>
          Average Staff Present:
          <select
            name="averageStaffPresent"
            value={formData.averageStaffPresent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="<50%">Less than 50%</option>
            <option value="50-60%">50% to 60%</option>
            <option value="60-70%">60% to 70%</option>
            <option value="70-80%">70% to 80%</option>
            <option value="80-90%">80% to 90%</option>
            <option value=">90%">Greater than 90%</option>
          </select>
        </label>

        <label>
          Total Classrooms:
          <select
            name="totalClassroomsCategory"
            value={formData.totalClassroomsCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="10 to 20">10 to 20</option>
            <option value="20 to 30">20 to 30</option>
            <option value="30 to 40">30 to 40</option>
            <option value="40 to 50">40 to 50</option>
          </select>
        </label>
        <label>
          Total Staff:
          <select
            name="totalStaffCategory"
            value={formData.totalStaffCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Below 10">Below 10</option>
            <option value="10 to 20">10 to 20</option>
            <option value="20 to 30">20 to 30</option>
            <option value="30 to 40">30 to 40</option>
            <option value="40 to 50">40 to 50</option>
          </select>
        </label>
        <b>
          <h3>Labs</h3>
        </b>
        <label>
          Computer Labs:
          <select
            name="computerLabsCategory"
            value={formData.computerLabsCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="1 to 2">1 to 2</option>
            <option value="2 to 3">2 to 3</option>
            <option value="3 to 4">3 to 4</option>
            <option value="4 to 5">4 to 5</option>
          </select>
        </label>
        <label>
          Chemistry Labs:
          <select
            name="chemistryLabsCategory"
            value={formData.chemistryLabsCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="1 to 2">1 to 2</option>
            <option value="2 to 3">2 to 3</option>
            <option value="3 to 4">3 to 4</option>
            <option value="4 to 5">4 to 5</option>
          </select>
        </label>
        <label>
          Biology Labs:
          <select
            name="biologyLabsCategory"
            value={formData.biologyLabsCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="1 to 2">1 to 2</option>
            <option value="2 to 3">2 to 3</option>
            <option value="3 to 4">3 to 4</option>
            <option value="4 to 5">4 to 5</option>
          </select>
        </label>
        <label>
          Physics Labs:
          <select
            name="physicsLabsCategory"
            value={formData.physicsLabsCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="1 to 2">1 to 2</option>
            <option value="2 to 3">2 to 3</option>
            <option value="3 to 4">3 to 4</option>
            <option value="4 to 5">4 to 5</option>
            {/* Add options for Physics Labs Category */}
          </select>
        </label>
        <label>
          Sports Room:
          <select
            name="sportsRoomCategory"
            value={formData.sportsRoomCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="1 to 2">1 to 2</option>
            <option value="2 to 3">2 to 3</option>
            <option value="3 to 4">3 to 4</option>
            <option value="4 to 5">4 to 5</option>
            {/* Add options for Sports Room Category */}
          </select>
        </label>
        <label className="label">
          <b> Canteen:</b>
          <select
            name="canteen"
            value={formData.canteen}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        <label className="label">
          Cooking Fuel:
          <input
            type="text"
            name="canteenCookingFuel"
            value={formData.canteenCookingFuel}
            onChange={handleChange}
            required={formData.canteen === "yes"}
            disabled={formData.canteen !== "yes"}
          />
        </label>

        <label className="label">
          Cooking Time:
          <select
            name="canteenCookingTimeCategory"
            value={formData.canteenCookingTimeCategory}
            onChange={handleChange}
            required={formData.canteen === "yes"}
            disabled={formData.canteen !== "yes"}
          >
            <option value="">Select</option>
            <option value="1 to 2">1 to 2 hours</option>
            <option value="2 to 3">2 to 3 hours</option>
            <option value="3 to 4">3 to 4 hours</option>
            <option value="4 to 5">4 to 5 hours</option>
          </select>
        </label>
        <label className="label">
          Ventilation Type:
          <select
            name="canteenVentilationType"
            value={formData.canteenVentilationType}
            onChange={handleChange}
            required={formData.canteen === "yes"}
            disabled={formData.canteen !== "yes"}
          >
            <option value="">Select Ventilation Type</option>
            <option value="window">Window</option>
            <option value="exhaust">Exhaust</option>
            <option value="chimney">Chimney</option>
            <option value="no ventilation">No Ventilation</option>
            <option value="other">Other</option>
          </select>
        </label>

        <label className="label">
          School Ventilation Type:
          <select
            name="schoolVentilationType"
            value={formData.schoolVentilationType}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="natural">Natural</option>
            <option value="artificial">Artificial</option>
          </select>
        </label>

        <label className="label">
          School Windows:
          <input
            type="number"
            name="schoolWindowsCategory"
            value={formData.schoolWindowsCategory}
            onChange={handleChange}
            required
          />
        </label>

        <label className="label">
          HVAC Ducts Maintenance:
          <select
            name="hvacDuctsMaintenance"
            value={formData.hvacDuctsMaintenance}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="recently">Recently</option>
            <option value="once a year">Once a year</option>
            <option value="2 to 3 years">2 to 3 years</option>
            <option value="none">None</option>
          </select>
        </label>
        <label className="label">
          HVAC No. of Vents:
          <select
            name="hvacNoOfVentsCategory"
            value={formData.hvacNoOfVentsCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select No. of Vents</option>
            <option value="10 to 20">10 to 20</option>
            <option value="20 to 30">20 to 30</option>
            <option value="30 to 40">30 to 40</option>
            <option value="40 to 50">40 to 50</option>
          </select>
        </label>

        <label className="label">
          Exhaust Fan Air Velocities Inlet:
          <input
            type="text"
            name="exhaustFanAirVelocitiesInlet"
            value={formData.exhaustFanAirVelocitiesInlet}
            onChange={handleChange}
            required
          />
        </label>

        <label className="label">
          Exhaust Fan Air Velocities Outlet:
          <input
            type="text"
            name="exhaustFanAirVelocitiesOutlet"
            value={formData.exhaustFanAirVelocitiesOutlet}
            onChange={handleChange}
            required
          />
        </label>
        <h3>Cleaning</h3>
        <label className="label">
          Cleaning Activities Method:
          <select
            name="cleaningActivitiesMethod"
            value={formData.cleaningActivitiesMethod}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="manually">Manually</option>
            <option value="by equipment">By Equipment</option>
          </select>
        </label>

        <label className="label">
          Cleaning Activities Times Manually:
          <select
            name="cleaningActivitiesTimesManually"
            value={formData.cleaningActivitiesTimesManually}
            onChange={handleChange}
            required
          >
            <option value="">Select Times Manually</option>
            <option value="1 to 2">1 to 2</option>
            <option value="2 to 3">2 to 3</option>
            <option value="3 to 4">3 to 4</option>
            <option value="none">None</option>
          </select>
        </label>

        <label className="label">
          Cleaning Activities Times Equipment:
          <select
            name="cleaningActivitiesTimesEquipment"
            value={formData.cleaningActivitiesTimesEquipment}
            onChange={handleChange}
            required
          >
            <option value="">Select Times with Equipment</option>
            <option value="1 to 2">1 to 2</option>
            <option value="2 to 3">2 to 3</option>
            <option value="3 to 4">3 to 4</option>
            <option value="none">None</option>
          </select>
        </label>
        <label className="label">
          Chemical-based Cleaning Products:
          <select
            name="chemicalBasedCleaningProducts"
            value={formData.chemicalBasedCleaningProducts}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>
        <label className="label">
          Cleaning Product Type:
          <select
            name="cleaningProductType"
            value={formData.cleaningProductType}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="detergent">Detergent</option>
            <option value="phenyl">Phenyl</option>
            <option value="bleaching agents">Bleaching Agents</option>
            <option value="harpic">Harpic</option>
            <option value="others">Others</option>
            <option value="None">None</option>
          </select>
        </label>

        <label className="label">
          Teaching Material Used:
          <input
            type="text"
            name="teachingMaterialUsed"
            value={formData.teachingMaterialUsed}
            onChange={handleChange}
            required
          />
        </label>
        <h3>Classroom</h3>

        <label className="label">
          Classroom Area:
          <select
            name="classroomAreaCategory"
            value={formData.classroomAreaCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="100 to 150">100 to 150 sq.m. (m2)</option>
            <option value="150 to 200">150 to 200 sq.m. (m2)</option>
            <option value="200 to 250">200 to 250 sq.m. (m2)</option>
            <option value="250 to 300">250 to 300 sq.m. (m2)</option>
          </select>
        </label>
        <label className="label">
          Classroom Student Count:
          <select
            name="classroomStudentCountCategory"
            value={formData.classroomStudentCountCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="15 to 25">15 to 25</option>
            <option value="25 to 40">25 to 40</option>
            <option value="40 to 60">40 to 60</option>
            <option value="60 to 80">60 to 80</option>
          </select>
        </label>
        <b>
          <a>Type of Furniture in Classroom:</a>
        </b>
        <br></br>
        <br></br>

        <label>
          No of Tables and Chairs :
          <select
            name="classroomTablesChairsCountCategory"
            value={formData.classroomTablesChairsCountCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="20 to 40">20 to 40</option>
            <option value="40 to 60">40 to 60</option>
            <option value="60 to 80">60 to 80</option>
            <option value="80 to 100">80 to 100</option>
            <option value="more">More than 100</option>
          </select>
        </label>

        <label>
          No of Benches:
          <select
            name="classroomBenchesCountCategory"
            value={formData.classroomBenchesCountCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="20 to 40">20 to 40</option>
            <option value="40 to 60">40 to 60</option>
            <option value="60 to 80">60 to 80</option>
            <option value="80 to 100">80 to 100</option>
            <option value="more">More than 100</option>
          </select>
        </label>

        <label>
          Classroom Furniture Material:
          <select
            name="classroomFurnitureMaterial"
            value={formData.classroomFurnitureMaterial}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="plastics">Plastics</option>
            <option value="wood">Wood</option>
            <option value="metals">Metals</option>
            <option value="polished wood">Polished Wood</option>
            <option value="others">Others</option>
          </select>
        </label>

        <label>
          Classroom Wall Material:
          <select
            name="classroomWallMaterial"
            value={formData.classroomWallMaterial}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="normal paint">Normal Paint</option>
            <option value="tiles">Tiles</option>
            <option value="PVC paneling">PVC Paneling</option>
            <option value="wood paneling">Wood Paneling</option>
            <option value="others">Others</option>
          </select>
        </label>

        <label>
          Classroom Floor Type:
          <select
            name="classroomFloorType"
            value={formData.classroomFloorType}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="normal cemented">Normal Cemented</option>
            <option value="tiles">Tiles</option>
            <option value="carpet">Carpet</option>
            <option value="marble">Marble</option>
            <option value="others">Others</option>
          </select>
        </label>

        <label>
          Classroom Blackboard Types:
          <select
            name="classroomBlackboardTypes"
            value={formData.classroomBlackboardTypes}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="normal blackboard">Normal Blackboard</option>
            <option value="whiteboard">Whiteboard</option>
            <option value="both">Both</option>
          </select>
        </label>

        <label>
          Classroom Teaching Material:
          <select
            name="classroomTeachingMaterial"
            value={formData.classroomTeachingMaterial}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="dust chalk">Dust Chalk</option>
            <option value="non-dust chalk">Non-Dust Chalk</option>
            <option value="marker">Marker</option>
            <option value="both of any two">Both of Any Two</option>
          </select>
        </label>

        <label>
          Classroom Activities:
          <select
            name="classroomActivities"
            value={formData.classroomActivities}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="arts">Arts</option>
            <option value="crafting">Crafting</option>
            <option value="clay work">Clay Work</option>
            <option value="both of any two">Both of Any Two</option>
          </select>
        </label>

        <label>
          Any seperate Ventilation Control in Classroom:
          <select
            name="classroomVentilationControl"
            value={formData.classroomVentilationControl}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        <label>
          Classroom Moisture Dampness:
          <select
            name="classroomMoistureDampness"
            value={formData.classroomMoistureDampness}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        <label>
          Classroom Temperature Humidity Control:
          <select
            name="classroomTempHumidityControl"
            value={formData.classroomTempHumidityControl}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        <label>
          Classroom Maintenance Issues:
          <select
            name="classroomMaintenanceIssues"
            value={formData.classroomMaintenanceIssues}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        <label>
          Classroom Location Floor Level:
          <input
            type="number"
            name="classroomLocationFloorLevel"
            value={formData.classroomLocationFloorLevel}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Window Count in Classroom:
          <input
            type="text"
            name="classroomWindowCount"
            value={formData.classroomWindowCount}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Curtains Present in Classroom:
          <select
            name="classroomCurtainsPresent"
            value={formData.classroomCurtainsPresent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        <label>
          Lunch Break No of Students Present:
          <select
            name="classroomLunchBreakStudentsPresent"
            value={formData.classroomLunchBreakStudentsPresent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
          </select>
        </label>

        <label>
          Distance of Classroom From Traffic Source:
          <select
            name="classroomDistanceFromTrafficSourceCategory"
            value={formData.classroomDistanceFromTrafficSourceCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="100 to 200">100 to 200</option>
            <option value="200 to 300">200 to 300</option>
            <option value="300 to 400">300 to 400</option>
            <option value="400 to 500">400 to 500</option>
          </select>
        </label>

        <label>
          Air Purifiers Used:
          <select
            name="classroomAirPurifiersUsed"
            value={formData.classroomAirPurifiersUsed}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        <label>
          Indoor Plants Present in Classroom :
          <select
            name="classroomIndoorPlantsPresent"
            value={formData.classroomIndoorPlantsPresent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.classroomIndoorPlantsPresent === "yes" && (
          <label>
            No of Indoor Plants in Classroom:
            <select
              name="classroomIndoorPlantsCountCategory"
              value={formData.classroomIndoorPlantsCountCategory}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="1 to 3">1 to 3</option>
              <option value="3 to 5">3 to 5</option>
              <option value="5 to 7">5 to 7</option>
              <option value="7 to 9">7 to 9</option>
            </select>
          </label>
        )}

        <label>
          Playground Distance from classroom :
          <select
            name="classroomPlaygroundDistanceCategory"
            value={formData.classroomPlaygroundDistanceCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
          </select>
        </label>

        <label>
          Other Activities Observed:
          <input
            type="text"
            name="otherActivitiesObserved"
            value={formData.otherActivitiesObserved}
            onChange={handleChange}
            required
          />
        </label>
        <button type="submit">Submit</button>

        {/* Add other form fields here */}
      </form>
    </>
  );
};

export default SchoolQues;
