import React, { useState } from 'react';
import '../../css/common.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const QuestionnaireMid = () => {
  const [formData, setFormData] = useState({
    locationId: '',
    participantId: '',
    placeOfDataCollection: '',
    dateOfParticipation: '',
    subjectInitials: '',
    dateOfBirth: '',
    age: '',
    locationCategory: '',
    gender: '',
    educationalQualifications: '',
    typeOfResidence: '',
    residenceDetails: '',
    distanceFromMainRoad: '',
    hasSeparateKitchen: '',
    numberOfWindowsInKitchen: '',
    lpgUsage: '',
    lpgUsageYears: '',
    keroseneUsage: '',
    keroseneUsageYears: '',
    biomassUsage: '',
    biomassUsageYears: '',
    electricHeaterUsage: '',
    electricHeaterUsageYears: '',
    mosquitoMattFrequency: '',
    mosquitoCoilFrequency: '',
    mosquitoLiquidVaporizerFrequency: '',
    dhoopStickFrequency: '',
    agarbattiFrequency: '',
    diyaFrequency: '',
    candleFrequency: '',
    keroseneLampFrequency: '',
    roomFreshenerFrequency: '',
    asthmaCurrent: '',
    asthmaCurrentYears: '',
    otherDiagnosis: '',
    otherDiagnosisYears: '',
    otherDiagnosisName: '',
    pneumoniaPast: '',
    pneumoniaPastYears: '',
    otherDisease: '',
    otherDiseaseName: '',
    otherDiseaseDuration: '',
    childCurrentSymptomsCough: '',
    childCurrentSymptomsShortnessOfBreath: '',
    childCurrentSymptomsPhlegm: '',
    childCurrentSymptomsChestTightness: '',
    childCurrentSymptomsWheeze: '',
    childCurrentSymptomsHeadache: '',
    childCurrentSymptomsAllergicSkinConditions: '',
    childCurrentSymptomsAllergicRunnyNose: '',
    childPastSymptomsCough: '',
    childPastSymptomsShortnessOfBreath: '',
    childPastSymptomsPhlegm: '',
    childPastSymptomsChestTightness: '',
    childPastSymptomsWheeze: '',
    childPastSymptomsHeadache: '',
    childPastSymptomsAllergicSkinConditions: '',
    childPastSymptomsAllergicRunnyNose: '',
    heightCm: '',
    weightKg: '',
    bloodPressureSys: '',
    bloodPressurediaSto: '',
    pulseBpm: '',
    spO2Percent: '',
    peakFlowMeterReading1: '',
    peakFlowMeterReading2: '',
    peakFlowMeterReading3: '',
    lungOscillometry: ''
  });



  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // const response = await axios.post('http://localhost:8081/mid/participants/add', {
        const response = await axios.post('http://89.116.20.162:8081/mid/participants/add', {

        ...formData,
      });

      if (response && response.data) {
        // Display success message immediately
        toast.success('Form submitted successfully', {
          // onClose: () => {
          //   window.location.reload();
          // },
        });

      } else {
        console.error('Error: Response or response data is undefined');
      }
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }

  };

  return (
    <>
      <ToastContainer /> {/* Add this to display toast notifications */}
      <form onSubmit={handleSubmit}>
        <center><h1 style={{ marginTop: "80px" }}>Health Questionnaire for 13 to 14 year child</h1></center>
        <label>
          Location Id:
          <input
            type="text"
            name="locationId"
            value={formData.locationId}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Participant Id:
          <input
            type="text"
            name="participantId"
            value={formData.participantId}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Place of Data Collection:
          <input
            type="text"
            name="placeOfDataCollection"
            value={formData.placeOfDataCollection}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Date of Participation:
          <input
            type="date"
            name="dateOfParticipation"
            value={formData.dateOfParticipation}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Participant Name:
          <input
            type="text"
            name="subjectInitials"
            value={formData.subjectInitials}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Date of Birth:
          <input
            type="date"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Age:
          <input
            type="number"
            name="age"
            value={formData.age}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Location Category:
          <select
            name="locationCategory"
            value={formData.locationCategory}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Schools">Schools</option>
            <option value="Residencies">Residencies</option>
            <option value="Malls_Shopping_Complexes">Malls/Shopping Complexes</option>
            <option value="Cinema_halls">Cinema halls</option>
            <option value="Offices">Offices</option>
            <option value="Restaurants">Restaurants</option>
            <option value="Eateries">Eateries</option>
          </select>
        </label>

        <label>
          Gender:
          <select
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Others">Others</option>
            <option value="Do not want to disclose">Do not want to disclose</option>
          </select>
        </label>

        <label>
          Educational Qualifications:
          <select
            name="educationalQualifications"
            value={formData.educationalQualifications}
            onChange={handleChange}
            required
          >
            <option value="">Select Educational Qualifications</option>
            <option value="Not_Educated">Not Educated</option>
            <option value="Primary_School">Primary School 1stto4th</option>
            <option value="Secondary_School">Secondary School 5thto10th</option>
            <option value="Junior_college_Bachelors_Diploma">Junior college Bachelors Diploma</option>
            <option value="Masters_and_above">Masters and above</option>
          </select>
        </label>
        <h3> Risk Factors</h3>
        <label>
          Type of Residence:
          <select
            name="typeOfResidence"
            value={formData.typeOfResidence}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Kaccha">Kaccha</option>
            <option value="Pakka">Pakka</option>
          </select>
        </label>

        {formData.typeOfResidence === 'Pakka' &&
          <div>
            <label>
              Residence Details:
              <select
                name="residenceDetails"
                value={formData.residenceDetails}
                onChange={handleChange}
                required
              >
                <option value="">Select Residence Type</option>
                <option value="Flat">Flat</option>
                <option value="Independent house">Independent house</option>
              </select>
            </label>
          </div>
        }

        <label>
          Distance from Main Road (in meters):
          <select
            name="distanceFromMainRoad"
            value={formData.distanceFromMainRoad}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="<100mts">&lt;100mts</option>
            <option value="100-200mts">100-200mts</option>
            <option value="200-500mts">200-500mts</option>
            <option value=">500mts">&gt;500mts</option>
          </select>
        </label>

        <label>
          Has Separate Kitchen:
          <select
            name="hasSeparateKitchen"
            value={formData.hasSeparateKitchen}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>


        {formData.hasSeparateKitchen === "Yes" &&
          <label>
            Number of Windows in the Kitchen:
            <select
              name="numberOfWindowsInKitchen"
              value={formData.numberOfWindowsInKitchen}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">≥3</option>
              <option value="Open Air cooking">Open Air cooking</option>
            </select>
          </label>
        }
        <label>
          LPG Usage:
          <select
            name="lpgUsage"
            value={formData.lpgUsage}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        {formData.lpgUsage === 'Yes' &&
          <label>
            LPG Usage Years:
            <input
              type="number"
              name="lpgUsageYears"
              value={formData.lpgUsageYears}
              onChange={handleChange}
              required
            />
          </label>
        }

        <label>
          Kerosene Usage:
          <select
            name="keroseneUsage"
            value={formData.keroseneUsage}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        {formData.keroseneUsage === 'Yes' &&
          <label>
            Kerosene Usage Years:
            <input
              type="number"
              name="keroseneUsageYears"
              value={formData.keroseneUsageYears}
              onChange={handleChange}
              required
            />
          </label>
        }

        <label>
          Biomass Usage:
          <select
            name="biomassUsage"
            value={formData.biomassUsage}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        {formData.biomassUsage === 'Yes' &&
          <label>
            Biomass Usage Years:
            <input
              type="number"
              name="biomassUsageYears"
              value={formData.biomassUsageYears}
              onChange={handleChange}
              required
            />
          </label>
        }

        <label>
          Electric Heater Usage:
          <select
            name="electricHeaterUsage"
            value={formData.electricHeaterUsage}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        {formData.electricHeaterUsage === 'Yes' &&
          <label>
            Electric Heater Usage Years:
            <input
              type="number"
              name="electricHeaterUsageYears"
              value={formData.electricHeaterUsageYears}
              onChange={handleChange}
              required
            />
          </label>
        }


        <label>
          Mosquito Matt Frequency:
          <select
            name="mosquitoMattFrequency"
            value={formData.mosquitoMattFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Mosquito Coil Frequency:
          <select
            name="mosquitoCoilFrequency"
            value={formData.mosquitoCoilFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Mosquito Liquid Vaporizer Frequency:
          <select
            name="mosquitoLiquidVaporizerFrequency"
            value={formData.mosquitoLiquidVaporizerFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Dhoop Stick Frequency:
          <select
            name="dhoopStickFrequency"
            value={formData.dhoopStickFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Agarbatti Frequency:
          <select
            name="agarbattiFrequency"
            value={formData.agarbattiFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Diya Frequency:
          <select
            name="diyaFrequency"
            value={formData.diyaFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Candle Frequency:
          <select
            name="candleFrequency"
            value={formData.candleFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>

        <label>
          Kerosene Lamp Frequency:
          <select
            name="keroseneLampFrequency"
            value={formData.keroseneLampFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
            required
          </select>
        </label>

        <label>
          Room Freshener Frequency:
          <select
            name="roomFreshenerFrequency"
            value={formData.roomFreshenerFrequency}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Never">Never</option>
            <option value="Once_a_month">Once a month</option>
            <option value="Once_a_week">Once a week</option>
            <option value="Daily">Daily</option>
            <option value="Twice_daily_or_more">Twice daily or more</option>
          </select>
        </label>
        <h3>Medical History</h3>
        <label>
          Asthma:
          <select
            name="asthmaCurrent"
            value={formData.asthmaCurrent}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.asthmaCurrent === 'yes' &&
          <label>
            Asthma Years:
            <input
              type="number"
              name="asthmaCurrentYears"
              value={formData.asthmaCurrentYears}
              onChange={handleChange}
              required
            />
          </label>
        }
        <label>
          Other Disease:
          <select
            name="otherDisease"
            value={formData.otherDisease}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.otherDisease === 'yes' && (
          <div>
            <label>
              Name of the Disease:
              <input
                type="text"
                name="otherDiseaseName"
                value={formData.otherDiseaseName}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Duration in years:
              <input
                type="number"
                name="otherDiseaseDuration"
                value={formData.otherDiseaseDuration}
                onChange={handleChange}
                required
              />
            </label>
          </div>
        )}
        <label>
          Pneumonia :
          <select
            name="pneumoniaPast"
            value={formData.pneumoniaPast}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </label>

        {formData.pneumoniaPast === 'yes' &&
          <label>
            Pneumonia Years:
            <input
              type="number"
              name="pneumoniaPastYears"
              value={formData.pneumoniaPastYears}
              onChange={handleChange}
              required
            />
          </label>
        }

        <label>
          Other Diagnosis:
          <select
            name="otherDiagnosis"
            value={formData.otherDiagnosis}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        {formData.otherDiagnosis === 'Yes' && (
          <>
            <label>
              Years since Other Diagnosis:
              <input
                type="number"
                name="otherDiagnosisYears"
                value={formData.otherDiagnosisYears}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Other Diagnosis Name:
              <input
                type="text"
                name="otherDiagnosisName"
                value={formData.otherDiagnosisName}
                onChange={handleChange}
                required
              />
            </label>
          </>
        )}

        {/* Add more fields as needed */}
        <h3>Are you experiencing any of the following symptoms currently ?</h3>

        {/* Child Symptoms */}
        <label>
          Cough:
          <select
            name="childCurrentSymptomsCough"
            value={formData.childCurrentSymptomsCough}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Shortness of Breath:
          <select
            name="childCurrentSymptomsShortnessOfBreath"
            value={formData.childCurrentSymptomsShortnessOfBreath}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Phlegm:
          <select
            name="childCurrentSymptomsPhlegm"
            value={formData.childCurrentSymptomsPhlegm}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Chest Tightness:
          <select
            name="childCurrentSymptomsChestTightness"
            value={formData.childCurrentSymptomsChestTightness}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Wheeze:
          <select
            name="childCurrentSymptomsWheeze"
            value={formData.childCurrentSymptomsWheeze}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Headache:
          <select
            name="childCurrentSymptomsHeadache"
            value={formData.childCurrentSymptomsHeadache}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Allergic Skin Conditions:
          <select
            name="childCurrentSymptomsAllergicSkinConditions"
            value={formData.childCurrentSymptomsAllergicSkinConditions}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Allergic Runny Nose:
          <select
            name="childCurrentSymptomsAllergicRunnyNose"
            value={formData.childCurrentSymptomsAllergicRunnyNose}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>
        <h3>Have you experienced any of the following symptoms within the past 12 months ?</h3>

        <label>
          Cough:
          <select
            name="childPastSymptomsCough"
            value={formData.childPastSymptomsCough}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>
        <label>
          Shortness of Breath:
          <select
            name="childPastSymptomsShortnessOfBreath"
            value={formData.childPastSymptomsShortnessOfBreath}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>
        <label>
          Phlegm:
          <select
            name="childPastSymptomsPhlegm"
            value={formData.childPastSymptomsPhlegm}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Chest Tightness:
          <select
            name="childPastSymptomsChestTightness"
            value={formData.childPastSymptomsChestTightness}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Wheeze:
          <select
            name="childPastSymptomsWheeze"
            value={formData.childPastSymptomsWheeze}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Headache:
          <select
            name="childPastSymptomsHeadache"
            value={formData.childPastSymptomsHeadache}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Allergic Skin Conditions:
          <select
            name="childPastSymptomsAllergicSkinConditions"
            value={formData.childPastSymptomsAllergicSkinConditions}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>

        <label>
          Allergic Runny Nose:
          <select
            name="childPastSymptomsAllergicRunnyNose"
            value={formData.childPastSymptomsAllergicRunnyNose}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </label>
        <h3>Clinical Examination</h3>
        <label>
          Height (cm):
          <input
            type="number"
            name="heightCm"
            value={formData.heightCm}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Weight (Kg):
          <input
            type="number"
            name="weightKg"
            value={formData.weightKg}
            onChange={handleChange}
            required
          />
        </label>
        {/* heightCm
weightKg
bloodPressureSys
bloodPressurediaSto
pulseBpm
spO2Percent
peakFlowMeterReading1
peakFlowMeterReading2
peakFlowMeterReading3
lungOscillometry */}
        <label>
          Blood Pressure systolic:
          <input
            type="text"
            name="bloodPressureSys"
            value={formData.bloodPressureSys}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Blood Pressure diastolic:
          <input
            type="text"
            name="bloodPressurediaSto"
            value={formData.bloodPressurediaSto}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Pulse (bpm):
          <input
            type="number"
            name="pulseBpm"
            value={formData.pulseBpm}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          SpO2 (%):
          <input
            type="number"
            name="spO2Percent"
            value={formData.spO2Percent}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Peak Flow Meter Reading 1:
          <input
            type="number"
            name="peakFlowMeterReading1"
            value={formData.peakFlowMeterReading1}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Peak Flow Meter Reading 2:
          <input
            type="number"
            name="peakFlowMeterReading2"
            value={formData.peakFlowMeterReading2}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Peak Flow Meter Reading 3:
          <input
            type="number"
            name="peakFlowMeterReading3"
            value={formData.peakFlowMeterReading3}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Lung Oscillometry:
          <select
            name="lungOscillometry"
            value={formData.lungOscillometry}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="Performed">Performed</option>
            <option value="NotPerformed">Not Performed</option>
          </select>
        </label>

        {formData.lungOscillometry === 'NotPerformed' && (
          <label>
            Why Not Performed:
            <input
              type="text"
              name="lungOscillometryReason"
              value={formData.lungOscillometryReason}
              onChange={handleChange}
              required
            />
          </label>
        )}
        <button type="submit">Submit</button>
      </form>
    </>
  );
};

export default QuestionnaireMid;
