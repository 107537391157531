import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Button from "@mui/material/Button";

// Importing images
import image1Cinemahalls from "../images/gallery/Cinemahalls/Image1.jpg";
import image2Cinemahalls from "../images/gallery/Cinemahalls/Image2.jpg";
import image3Cinemahalls from "../images/gallery/Cinemahalls/Image3.jpg";
import image4Cinemahalls from "../images/gallery/Cinemahalls/Image4.jpg";

import image1Malls from "../images/gallery/Malls/Image1.jpg";
import image2Malls from "../images/gallery/Malls/Image2.jpg";
import image3Malls from "../images/gallery/Malls/Image3.jpg";
import image4Malls from "../images/gallery/Malls/Image4.jpg";
import image5Malls from "../images/gallery/Malls/Image5.jpg";
import image6Malls from "../images/gallery/Malls/Image6.jpg";

import image1Offices from "../images/gallery/Offices/Image1.jpg";
import image2Offices from "../images/gallery/Offices/Image2.jpg";
import image3Offices from "../images/gallery/Offices/Image3.jpg";
import image4Offices from "../images/gallery/Offices/Image4.jpg";
import image5Offices from "../images/gallery/Offices/Image5.jpg";

const GalleryPreview = () => {
  const images = [
    { src: image1Cinemahalls, alt: "Cinema Hall Image 1" },
    { src: image2Cinemahalls, alt: "Cinema Hall Image 2" },
    { src: image3Cinemahalls, alt: "Cinema Hall Image 3" },
    { src: image4Cinemahalls, alt: "Cinema Hall Image 4" },

    { src: image1Malls, alt: "Mall Image 1" },
    { src: image2Malls, alt: "Mall Image 2" },
    { src: image3Malls, alt: "Mall Image 3" },
    { src: image4Malls, alt: "Mall Image 4" },
    { src: image5Malls, alt: "Mall Image 5" },

    { src: image1Offices, alt: "Office Image 1" },
    { src: image2Offices, alt: "Office Image 2" },
    { src: image3Offices, alt: "Office Image 3" },
    { src: image4Offices, alt: "Office Image 4" },
    { src: image5Offices, alt: "Office Image 5" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    centerMode: true,
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false, // Disable next and prev arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };
  

  return (
    <section
      id="gallery"
      style={{
        background: "linear-gradient(120deg, #f0f4f8, #e1e8f0)",
        padding: "50px 0",
      }}
    >
      <div className="contaiiiiner text-centerrr">
        <h2
          style={{
            color: "#4A90E",
            fontSize: "2rem",
            marginBottom: "20px",
            textTransform: "uppercase",
            letterSpacing: "2px",
            marginTop: "-20px"
          }}
        >
          Gallery
        </h2>

        <Slider {...settings}>
          {images.slice(0, 6).map((image, index) => (
            <div key={index} style={{ padding: "15px" }}>
              <img
                src={image.src}
                alt={image.alt}
                style={{
                  width: "95%",
                  height: "auto",
                  objectFit: "cover",
                  borderRadius: "15px",
                  boxShadow: "0 8px 15px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  cursor: "pointer",
                }}
                className="image-hover"
              />
            </div>
          ))}
        </Slider>

        {/* Button to see more images */}
        <div style={{ marginTop: "0px" }}>
          <Link to="/gallery" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                fontSize: "1.1rem",
                backgroundColor: "#3498db",
                padding: "5px 25px",
                textTransform: "capitalize",
                borderRadius:"6px",
                fontWeight: "700",
                width: "250px",
                margin: "0 auto",
                display: "block",
                marginTop:"30px"
              }}
              className="see-more-btn"
            >
              Explore More
            </Button>
          </Link>
        </div>
      </div>

      {/* Style for Hover Effect */}
      <style>
        {`
          .slick-slide img {
            transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
          }
          
          .slick-slide img:hover {
            transform: scale(1.1);
            box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
          }

          .slick-dots li.slick-active button:before {
            color: #4A90E2;
            opacity: 1;
          }

          .slick-dots li button:before {
            color: #b5b5b5;
          }
        `}
      </style>
    </section>
  );
};

export default GalleryPreview;
