import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/Team.css";

// Importing all 24 team member images
import member1 from "../images/teams/1.jpg";
import member2 from "../images/teams/2.png";
import member3 from "../images/teams/3.jpg";
import member4 from "../images/teams/4.jpg";
import member5 from "../images/teams/5.jpg";
import member6 from "../images/teams/6.jpg";
import member7 from "../images/teams/7.jpg";
import member8 from "../images/teams/8.jpg";
import member9 from "../images/teams/9.jpg";
import member10 from "../images/teams/10.jpg";
import member11 from "../images/teams/11.jpg";
import member12 from "../images/teams/12.jpg";
import member13 from "../images/teams/13.jpg";
import member14 from "../images/teams/14.jpg";
import member15 from "../images/teams/15.jpeg";
import member16 from "../images/teams/16.jpg";
import member17 from "../images/teams/17.jpg";
import member18 from "../images/teams/18.jpg";
import member19 from "../images/teams/19.jpeg";
import member20 from "../images/teams/20.jpg";
import member21 from "../images/teams/21.jpg";
import member22 from "../images/teams/22.jpg";
import member23 from "../images/teams/23.jpg";
import member24 from "../images/teams/24.jpg";

// List of team members
const teamMembers = [
  { img: member1, name: "Ajay Ojha" },
  { img: member2, name: "Prasad Pawar" },
  { img: member3, name: "Harshada Sawant" },
  { img: member4, name: "Sonalee Borse" },
  { img: member5, name: "Bishnupriya Tudu" },
  { img: member6, name: "Shruti Mahale" },
  { img: member7, name: "Tanmay Shrivastav" },
  { img: member8, name: "Soma Shekhar" },
  { img: member9, name: "Ashish Singh" },
  { img: member10, name: "Satish Chandra" },
  { img: member11, name: "Prabhat Yadav" },
  { img: member12, name: "Anvay Vaidya" },
  { img: member13, name: "Raj " },
  { img: member14, name: "Digvijay Pawar" },
  { img: member15, name: "Atique Barudgar" },
  { img: member16, name: "Ruchita Haldar" },
  { img: member17, name: "Ritesh Gujar" },
  { img: member18, name: "Saptarshi Dutta" },
  { img: member19, name: "Rushikesh Kharade" },
  { img: member20, name: "Rigved Tupsakhare" },
  { img: member21, name: "Nikhil Kane" },
  { img: member22, name: "Rutuja Bhasme" },
  { img: member23, name: "Tanmayee Burke" },
  { img: member24, name: "Shraddha Shinde" },
];

const Team = () => {
  const navigate = useNavigate();

  return (
    <div id="team" className="team-section">
      {/* <button className="close-btn" onClick={() => navigate("/")}>
        &times;
      </button> */}
      <h2>Meet Our Team</h2>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <img
              src={member.img}
              alt={`Team Member ${index + 1}`}
              className="team-image"
            />
            <h3>{member.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
