import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import overviewImage from "../images/overview/overview.jpg";
import "../css/Overview.css";

const Overview = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="overview-wrapper">
      {/* <div className="overview-header">
        <button className="back-button" onClick={goBack}>
          <FaArrowLeft className="back-icon" />
          <span>Back</span>
        </button>
        <h1 className="overview-title">Overview</h1>
      </div> */}
      <div className="image-container">
        <img src={overviewImage} alt="Overview" className="responsive-image" />
      </div>
    </div>
  );
};

export default Overview;
