import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import '../../css/ParameterWise.css';

import dayjs from 'dayjs';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ParameterWise = ({ region, season }) => {
  const [data, setData] = useState(null);

  // Configuration for all the parameters to be charted
  const parameterConfig = {
    co: { name: 'Carbon Monoxide (CO)', color: '#FF6384', dataKey: 'co' },
    co2: { name: 'Carbon Dioxide (CO2)', color: '#4bc0c0', dataKey: 'co2' },
    vocTigerxt: { name: 'Volatile Organic Compounds (VOC)', color: '#FF9F40', dataKey: 'voc' },
    temperature: { name: 'Temperature', color: '#4BC0C0', dataKey: 'temperature' },
    relativeHumidity: { name: 'Relative Humidity', color: '#36a2eb', dataKey: 'relativeHumidity' },
    windVelocity: { name: 'Wind Velocity', color: '#9966FF', dataKey: 'windVelocity' },
    grimms: {
      name: 'Particulate Matter',
      color: '#36A2EB',
      subParams: [
        { key: 'pm1', name: 'PM1' },
        { key: 'pm2_5', name: 'PM2.5' },
        { key: 'pm10', name: 'PM10' },
      ],
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      if (region && season) {
        try {
          const response = await fetch(
            `http://89.116.20.162:8081/api/combined-data/region-season?region=${region}&season=${season}`
            // `http://192.168.1.57:8081/api/combined-data/region-season?region=${region}&season=${season}`
          );
          if (!response.ok) {
            throw new Error('Failed to fetch data');
          }
          const result = await response.json();
          console.log('Fetched Data:', result); // Debugging log for API response
          setData(result);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    fetchData();
  }, [region, season]);

  const prepareChartData = (param) => {
    if (!data) return null;

    const config = parameterConfig[param];
    let chartData = {
      labels: [],
      datasets: [],
    };

    let minValue = Infinity;
    let maxValue = -Infinity;

    if (param !== 'grimms') {
      if (data[param] && Array.isArray(data[param])) {
        const sortedData = [...data[param]].sort(
          (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
        );

        chartData.labels = sortedData.map((item) =>
          dayjs(item.dateTime).format('YYYY-MM-DD')
        );

        chartData.datasets = [
          {
            label: config.name,
            data: sortedData.map((item) => {
              const value = parseFloat(item[config.dataKey]) || 0; // Ensure numeric value
              minValue = Math.min(minValue, value);
              maxValue = Math.max(maxValue, value);
              return parseFloat(value.toFixed(1)); // Round to 1 decimal place
            }),
            borderColor: config.color,
            backgroundColor: `${config.color}33`,
            tension: 0.1,
          },
        ];
      }
    } else {
      if (data.grimms) {
        const sortedData = [...data.grimms].sort(
          (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
        );

        chartData.labels = sortedData.map((item) =>
          dayjs(item.dateTime).format('YYYY-MM-DD')
        );

        chartData.datasets = config.subParams.map((subParam) => {
          const subData = sortedData.map((item) => {
            const value = parseFloat(item[subParam.key]) || 0;
            minValue = Math.min(minValue, value);
            maxValue = Math.max(maxValue, value);
            return parseFloat(value.toFixed(1));
          });
          return {
            label: subParam.name,
            data: subData,
            borderColor: config.color,
            backgroundColor: `${config.color}33`,
            tension: 0.1,
          };
        });
      }
    }

    // Round min and max values
    minValue = minValue === Infinity ? 0 : parseFloat(minValue.toFixed(1));
    maxValue = maxValue === -Infinity ? 0 : parseFloat(maxValue.toFixed(1));

    return { chartData, minValue, maxValue };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      tooltip: { mode: 'index', intersect: false },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
        grid: {
          display: false,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          callback: () => "",   // Ensures the formatted date-time is shown as a label
        }
      },
      y: {
        title: {
          display: true,
          text: 'Value'
        }
      }
    },
  };

  return (
    <div className="parameterwise-container">
      {!data && <p className="loading-message">Loading data...</p>}

      {data && (
        <div className="graph-grid">
          {Object.keys(parameterConfig).map((param) => {
            const { chartData, minValue, maxValue } = prepareChartData(param);
            return chartData ? (
              <div key={param} className="graph-container">
                <div style={{ height: '300px' }}>
                  <Line
                    data={chartData}
                    options={{
                      ...chartOptions,
                      plugins: {
                        ...chartOptions.plugins,
                        title: { display: true, text: parameterConfig[param].name },
                      },
                    }}
                  />
                </div>
                <p>
                  <strong>Min Value:</strong> {minValue}
                </p>
                <p>
                  <strong>Max Value:</strong> {maxValue}
                </p>
              </div>
            ) : null;
          })}
        </div>
      )}
    </div>
  );
};

export default ParameterWise;
