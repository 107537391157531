import React, { useState } from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-image-lightbox'; // Import Lightbox
import 'react-image-lightbox/style.css'; // Import Lightbox CSS
import '../css/GallerySection.css';

// Import images for each section
import image1Cinemahalls from '../images/gallery/Cinemahalls/Image1.jpg';
import image2Cinemahalls from '../images/gallery/Cinemahalls/Image2.jpg';
import image3Cinemahalls from '../images/gallery/Cinemahalls/Image3.jpg';
import image4Cinemahalls from '../images/gallery/Cinemahalls/Image4.jpg';

import image1Malls from '../images/gallery/Malls/Image1.jpg';
import image2Malls from '../images/gallery/Malls/Image2.jpg';
import image3Malls from '../images/gallery/Malls/Image3.jpg';
import image4Malls from '../images/gallery/Malls/Image4.jpg';
import image5Malls from '../images/gallery/Malls/Image5.jpg';
import image6Malls from '../images/gallery/Malls/Image6.jpg';

import image1Offices from '../images/gallery/Offices/Image1.jpg';
import image2Offices from '../images/gallery/Offices/Image2.jpg';
import image3Offices from '../images/gallery/Offices/Image3.jpg';
import image4Offices from '../images/gallery/Offices/Image4.jpg';
import image5Offices from '../images/gallery/Offices/Image5.jpg';
import image6Offices from '../images/gallery/Offices/Image6.jpg';

import image7Offices from '../images/gallery/Offices/Image7.jpg';
import image8Offices from '../images/gallery/Offices/Image8.jpg';
import image9Offices from '../images/gallery/Offices/Image9.jpg';

import image1Restaurants from '../images/gallery/Restaurants/Image1.jpg';
import image2Restaurants from '../images/gallery/Restaurants/Image2.jpg';
import image3Restaurants from '../images/gallery/Restaurants/Image3.jpg';
import image4Restaurants from '../images/gallery/Restaurants/Image4.jpg';
import image5Restaurants from '../images/gallery/Restaurants/Image5.jpg';

const GallerySection = () => {
  // All images grouped into one array
  const images = [

    { src: image3Malls, width: 4, height: 3 },
    { src: image4Malls, width: 4, height: 3 },
    { src: image4Offices, width: 4, height: 3 },
    { src: image6Malls, width: 4, height: 3 },
    { src: image4Cinemahalls, width: 4, height: 3 },
    { src: image6Offices, width: 4, height: 3 },
    { src: image8Offices, width: 4, height: 3 },
    { src: image9Offices, width: 4, height: 3 },


    { src: image1Cinemahalls, width: 4, height: 3 },
    { src: image2Cinemahalls, width: 4, height: 3 },
    { src: image3Cinemahalls, width: 4, height: 3 },

    { src: image1Malls, width: 4, height: 3 },
    { src: image2Malls, width: 4, height: 3 },
    
    { src: image5Malls, width: 4, height: 3 },

    { src: image1Offices, width: 4, height: 3 },
    { src: image2Offices, width: 4, height: 3 },
    { src: image3Offices, width: 4, height: 3 },
    { src: image5Offices, width: 4, height: 3 },
    { src: image7Offices, width: 4, height: 3 },
   

    { src: image1Restaurants, width: 4, height: 3 },
    { src: image2Restaurants, width: 4, height: 3 },
    { src: image3Restaurants, width: 4, height: 3 },
    { src: image4Restaurants, width: 4, height: 3 },
    { src: image5Restaurants, width: 4, height: 3 },
  ];

  const [isOpen, setIsOpen] = useState(false); // Lightbox state
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Track which image is clicked

  // Open Lightbox
  const openLightbox = (event, { index }) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  // Get the current image
  const currentImage = images[currentImageIndex]?.src;

  return (
    <div className="gallery-container">
      <h2>Full Gallery</h2>
      <Gallery photos={images} onClick={openLightbox} />

      {isOpen && (
        <Lightbox
          mainSrc={currentImage}
          nextSrc={images[(currentImageIndex + 1) % images.length]?.src}
          prevSrc={
            images[
              (currentImageIndex + images.length - 1) % images.length
            ]?.src
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setCurrentImageIndex(
              (currentImageIndex + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setCurrentImageIndex((currentImageIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default GallerySection;
