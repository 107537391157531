import React, { useState } from "react";
import "../css/Statistics.css";

// Boxplot images
import Boxplot1 from "../images/boxplots/1.png";
import Boxplot2 from "../images/boxplots/2.png";
import Boxplot3 from "../images/boxplots/3.png";
import Boxplot4 from "../images/boxplots/4.png";
import Boxplot5 from "../images/boxplots/5.png";
import Boxplot6 from "../images/boxplots/6.png";
import Boxplot7 from "../images/boxplots/7.png";
import Boxplot8 from "../images/boxplots/8.png";
import Boxplot9 from "../images/boxplots/9.png";

// Exceedance images
import Exceedance1 from "../images/exceedance/1.png";
import Exceedance2 from "../images/exceedance/2.png";
import Exceedance3 from "../images/exceedance/3.png";
import Exceedance4 from "../images/exceedance/4.png";
import Exceedance5 from "../images/exceedance/5.png";
import Exceedance6 from "../images/exceedance/6.png";

// PCA Plot image
import PCAPlot from "../images/pca/pcaplot.png";

const Statistics = () => {
  const [activeTab, setActiveTab] = useState("boxplots");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleImageClick = (imageSrc) => {
    setModalImage(imageSrc);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "boxplots":
        return (
          <div className="content-grid">
            {[Boxplot1, Boxplot2, Boxplot3, Boxplot4, Boxplot5, Boxplot6, Boxplot7, Boxplot8, Boxplot9].map(
              (src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Boxplot ${index + 1}`}
                  className="content-image"
                  onClick={() => handleImageClick(src)}
                />
              )
            )}
          </div>
        );
      case "exceedance":
        return (
          <div className="content-grid">
            {[Exceedance1, Exceedance2, Exceedance3, Exceedance4, Exceedance5, Exceedance6].map(
              (src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Exceedance ${index + 1}`}
                  className="content-image"
                  onClick={() => handleImageClick(src)}
                />
              )
            )}
          </div>
        );
      case "pca":
        return (
          <div className="content-single">
            <img
              src={PCAPlot}
              alt="PCA Plot"
              className="content-image-large"
            />
            <div className="pca-text">
              <h3>Principal Component Analysis (PCA)</h3>
              <p>
                This 3D plot represents a Principal Component Analysis (PCA)
                matrix, where environmental variables are plotted in three
                dimensions based on their principal components. The variables
                such as PM1, PM2.5, PM10, CO2, and TMP are distributed across
                Component 1, Component 2, and Component 3, highlighting their
                respective contributions to the overall variance in the
                dataset. This plot helps in visualizing the clustering of
                variables and their correlation with each other.
              </p>
            </div>
          </div>
        );
      case "details":
        return (
          <div className="content-container">
  <div className="content-text">
    <h2>Exceedance Summary</h2>
    <p>
      The exceedance levels of various air pollutants across different locations highlight a significant disparity in pollution severity.
      PM10 concentrations show a broad distribution, with the majority of locations exhibiting moderate exceedances at 10%-50% above the threshold.
      However, several areas experience severe pollution, with concentrations exceeding 500% to 1000%, indicating extremely high levels of particulate matter.
    </p>
    <p>
      Similarly, PM2.5 levels follow a comparable trend, where concentrations remain under 50 µg/m³ at lower exceedance levels, but as exceedances increase to 200%-1000%, PM2.5 levels frequently surpass 100 µg/m³, with some locations exceeding 250 µg/m³, indicating dangerous air quality.
    </p>
    <p>
      PM1 concentrations also show significant violations, particularly at the higher exceedance levels of 100%-1000%, where many locations report levels crossing 50 µg/m³ and reaching over 200 µg/m³, highlighting critical pollution hotspots.
    </p>
    <p>
      TVOC concentrations are generally low across most locations, with 10%-100% exceedance levels concentrated around 1 ppm. However, a few locations exhibit higher concentrations at 500% exceedance, though such occurrences are relatively rare.
    </p>
    <p>
      For CO2, the exceedance levels are generally within the range of 1000-2000 ppm at 50%-100% exceedance, but several outliers at 200%-500% exceedance surpass 5000 ppm, with a few locations reaching nearly 9000 ppm, indicating extremely poor air quality in those areas.
    </p>
    <p>
      In contrast, CO levels show relatively low exceedances, remaining below 10 ppm in most locations at 50%-100% exceedance levels.
      Only a few areas show exceedances of 200%-500%, reaching up to 40 ppm, with very few instances of exceedances beyond this.
    </p>
  </div>

  <div className="content-text">
    <h3>Exceedance Summary (Continued)</h3>
    <p>
      In summary, pollutants like PM10, PM2.5, PM1, and CO2 show widespread and significant exceedances, especially at higher percentages, indicating serious air quality violations in several areas. Meanwhile, CO and TVOC exhibit lower exceedance levels, with occasional spikes in certain locations, but these remain less frequent compared to the particulate matter and CO2 levels. This analysis highlights that particulate matter and CO2 are the most critical pollutants in terms of exceedance levels, posing major environmental and health risks in the affected areas.
    </p>
  </div>

  <div className="content-text">
    <h2>Box Plot Summary</h2>
    <p>
      The boxplots of PM10, PM2.5, and PM1 concentrations across various locations reveal significant differences in air quality. Restaurants and Eateries consistently display the highest pollution levels, with the widest range and notable outliers for all particulate sizes, particularly PM10 and PM2.5, which have medians close to 100 µg/m³. Residences also show a broader spread, especially for PM2.5, with some extreme values exceeding 150 µg/m³. In contrast, Offices and Schools exhibit lower median concentrations, with Schools showing the least pollution and few outliers across all particle sizes. Shopping Malls and Cinema Halls have tightly grouped distributions, with median concentrations well below 50 µg/m³ for PM2.5 and PM1, and only occasional spikes. Overall, Restaurants and Eateries experience the highest pollution, while Schools, Shopping Malls, and Cinema Halls show relatively cleaner air.
    </p>
    <p>
      The boxplot in the image shows the distribution of Total Volatile Organic Compounds (TVOC) levels, measured in parts per million (ppm), across six categories of locations: Cinema Hall, Offices, Residences, Restaurants and Eateries, Schools, and Shopping Malls. Residences and Restaurants and Eateries exhibit the highest number of outliers, with TVOC levels reaching over 3 ppm, though their medians remain relatively low. Offices and Shopping Malls also show some outliers but have tighter distributions with lower median levels. Cinema Halls and Schools have consistently low TVOC concentrations, with Schools showing almost no variation. The data suggest a wider range of TVOC exposure in locations like Residences and Restaurants, while other categories maintain more stable levels.
    </p>
    <p>
      The box plot displays the concentration of carbon dioxide (CO2) in parts per million (ppm) across various categories of locations, including Cinema halls, Offices, Residences, Restaurants and Eateries, Schools, and Shopping Malls. The data shows that CO2 levels vary significantly between categories. Schools have the highest CO2 levels with many extreme outliers, reaching over 4000 ppm. Offices and Residences show relatively moderate levels, but with wider variability and outliers. Cinema halls have the lowest levels of CO2, with fewer outliers, while Shopping Malls and Restaurants show similar median levels but with slightly higher variability in CO2 concentrations. This suggests that schools may have poorer ventilation or higher occupancy rates compared to other locations.
    </p>
    <p>
      The boxplot in the image displays the distribution of carbon monoxide (CO) levels, measured in parts per million (ppm), across six location categories: Cinema Hall, Offices, Residences, Restaurants and Eateries, Schools, and Shopping Malls. Restaurants and Eateries show the highest median and variability in CO levels, with some extreme values reaching up to 35 ppm. Offices also exhibit noticeable outliers, though the median remains low. In contrast, Cinema Halls, Schools, and Shopping Malls have consistently low carbon monoxide levels, with minimal spread and outliers. Residences show moderate levels with a few high outliers, indicating some instances of elevated CO.
    </p>
    <p>
      The box plot displays the temperature distribution across different categories of locations, including cinema halls, offices, residences, restaurants and eateries, schools, and shopping malls. The temperatures vary significantly between categories, with offices showing a broader range, from approximately 20°C to 37.5°C, indicating variability in temperature control. Cinema halls and shopping malls exhibit lower median temperatures around 30°C, but cinema halls have more outliers, suggesting occasional temperature spikes. Residences and restaurants have similar median temperatures, while schools show the highest median value around 35°C. Overall, the plot highlights how temperature settings differ across these various environments.
    </p>
    <p>
      The box plot illustrates the distribution of relative humidity across different categories of locations, including cinema halls, offices, residences, restaurants and eateries, schools, and shopping malls. Offices and restaurants exhibit a broader range of relative humidity, spanning from around 30% to over 80%, indicating variability in air conditioning and ventilation. Residences and offices have similar median humidity levels, near 65%. Cinema halls and shopping malls show lower median humidity, around 55%, with cinema halls having a few higher outliers. Schools demonstrate a more consistent pattern, but with some higher values as outliers. Overall, the data suggests diverse humidity control settings across these various environments.
    </p>
    <p>
      The box plot displays wind velocity data across various categories of locations, including cinema halls, offices, residences, restaurants and eateries, schools, and shopping malls. Overall, the wind velocity is generally low across all categories. Cinema halls have the lowest wind velocity, with almost no variation, indicating minimal air movement. Offices, residences, and restaurants have slightly higher wind velocities, ranging up to around 0.3-0.4 m/s, with many outliers, suggesting occasional drafts. Schools exhibit the widest range, with wind velocities going up to 0.8 m/s and numerous outliers, indicating varied airflow patterns. Shopping malls have lower median wind velocities, with a few outliers. The data reflects differences in ventilation and airflow across these environments.
    </p>
  </div>
</div>

        );
      default:
        return null;
    }
  };

  return (
    <div className="statistics-container">
      <h1 className="statistics-title">Statistics</h1>
      <div className="bats">
      <button
          className={`bat ${activeTab === "boxplots" ? "active" : ""}`}
          onClick={() => setActiveTab("boxplots")}
        >
          Boxplots
        </button>
        <button
          className={`bat ${activeTab === "exceedance" ? "active" : ""}`}
          onClick={() => setActiveTab("exceedance")}
        >
          Exceedance
        </button>
        <button
          className={`bat ${activeTab === "pca" ? "active" : ""}`}
          onClick={() => setActiveTab("pca")}
        >
          PCA
        </button>
        <button
          className={`bat ${activeTab === "details" ? "active" : ""}`}
          onClick={() => setActiveTab("details")}
        >
          Statistical Summary
        </button>
      </div>
      {renderContent()}
      {isModalOpen && (
        <div className="modal open">
          <div className="modal-content">
            <img src={modalImage} alt="Expanded view" className="modal-image" />
            <button className="close-btn" onClick={handleCloseModal}>
              &times;
            </button>
          </div>
        </div>
      )}    </div>
  );
};

export default Statistics;
