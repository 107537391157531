import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../css/BlogPage.css'; 

// Importing images for blog posts
import image1 from '../../src/images/blogs/image1.jpg';
import image2 from '../../src/images/blogs/image2.jpg';
import image3 from '../../src/images/blogs/image3.jpg';
import image4 from '../../src/images/blogs/image4.jpg';
import image5 from '../../src/images/blogs/image5.jpg';
import image6 from '../../src/images/blogs/image6.jpg';
import image7 from '../../src/images/blogs/image6.jpg';
import image8 from '../../src/images/blogs/image8.gif';
import image9 from '../../src/images/blogs/image6.jpg';
import image10 from '../../src/images/blogs/image6.jpg';
import image11 from '../../src/images/blogs/image6.jpg';
import image12 from '../../src/images/blogs/image6.jpg';
import image13 from '../../src/images/blogs/image6.jpg';
import image14 from '../../src/images/blogs/image6.jpg';
import image15 from '../../src/images/blogs/image6.jpg';
import image16 from '../../src/images/blogs/image6.jpg';
import image17 from '../../src/images/blogs/image6.jpg';
import image18 from '../../src/images/blogs/image6.jpg';
import image19 from '../../src/images/blogs/image6.jpg';
import image20 from '../../src/images/blogs/image6.jpg';
import image21 from '../../src/images/blogs/image6.jpg';
import image22 from '../../src/images/blogs/image6.jpg';
import image23 from '../../src/images/blogs/image6.jpg';
import image24 from '../../src/images/blogs/image6.jpg';
import image25 from '../../src/images/blogs/image6.jpg';



const blogs = [
    {
        id: 1,
        image: image1,
        title: "Comprehensive Indoor Air Quality Monitoring Across MMR ",
        description: "Discover our latest initiative to monitor indoor air quality in the Mumbai Metropolitan Region.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaqmmr-indoorairquality-airpollution-activity-7226203049508106240-oslX/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 2,
        image: image1,
        title: "Evaluation of Air Quality in Different Indoor Environments in MMR",
        description: "Techknowgreen Solutions Limited is thrilled to announce this esteemed project.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_project-iaq-in-mmr-ugcPost-7181722922540032002-SJbf/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 3,
        image: image5,
        title: "Achieving Thermal Comfort: Why It's Essential for Your Health and Wellbeing",
        description: "Explore the importance of maintaining thermal comfort indoors.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaq-indoorairquality-thermalcomfort-activity-7221007224834248704-t0tM/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 4,
        image: image9,
        title: "The Hidden World of Air Pollution: Indoors and Beyond",
        description: "Dive into the unseen dangers of indoor air pollution.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_indoorairquality-safeiaq-airpollution-activity-7218819390262145024-nxa8/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 5,
        image: image5,
        title: "Be Inspired by Our Latest Blog Post!",
        description: "Continuing our series on indoor air quality and healthy living.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_indoorairquality-healthyliving-cookingtips-activity-7221437004729765888-H6rS/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 6,
        image: image6,
        title: "The Hidden Impact of Sweeping: How It Raises Indoor Pollution",
        description: "Understanding the importance of proper cleaning techniques.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_indoorairquality-healthyliving-homecleaningtips-activity-7221817184581275650-aueN/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 7,
        image: image7,
        title: "Green Guardians – How Indoor Plants Improve Air Quality",
        description: "Discover how indoor plants contribute to a healthier environment.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaq-indoorairquality-indoorplants-activity-7223931775503261697-QfeJ/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 8,
        image: image8,
        title: "SAFE IAQ MMR: A Study for Better Indoor Air Quality",
        description: "An exciting new study in the MMR to improve air quality standards.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaqmmr-indoorairquality-airpollution-activity-7224380453405786112-kEA7/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 9,
        image: image9,
        title: "Indoor Air Quality Analysis: Bank in Matheran – Part 2: CO2",
        description: "Analyzing carbon dioxide levels in indoor environments.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaqmmr-indoorairquality-co2-activity-7239143876895137793-At4F/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 10,
        image: image10,
        title: "Health Risks of Indoor Air Pollution: What You Need to Know",
        description: "Understanding how indoor air pollution impacts your health.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaq-indoorairquality-healtheffects-activity-7219545152481054720-Olrp/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 11,
        image: image11,
        title: "Unveiling Sick Building Syndrome: How Indoor Air Quality Affects Your Health",
        description: "Learn about the symptoms and causes of Sick Building Syndrome.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaq-indoorairquality-sickbuildingsyndrome-activity-7219939651816472576-mNPB/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 12,
        image: image12,
        title: "Identifying Common Sources of Indoor Air Pollution",
        description: "A guide to recognizing pollutants in your environment.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_indoorairquality-safeiaq-pollutionsources-activity-7219194011738071043-rrCB/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 13,
        image: image13,
        title: "Indoor Air Quality analysis of a bank in Matheran – Part 4: Total Volatile Organic Compounds (TVOCs)",
        description: "Exploring the levels of TVOCs in indoor spaces of a bank.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaqmmr-indoorairquality-tvocs-activity-7243849489910824960-JkoI/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 14,
        image: image14,
        title: "Indoor Air Quality Analysis of a Residence in Matheran",
        description: "A comprehensive look into the IAQ of a residence in Matheran.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_tsl-safeiaqmmr-indoorairquality-activity-7246384842014130176-Qo2q/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 15,
        image: image15,
        title: "Indoor Air Quality Analysis: AC Restaurant in Matheran – Part 3",
        description: "Assessing TVOCs levels in an AC restaurant environment.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaqmmr-indoorairquality-health-activity-7234791570259009536-fPCw/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 16,
        image: image16,
        title: "Indoor Air Quality Analysis of a bank in Matheran – Part 1",
        description: "Analyzing particulate matter (PM) in a bank's indoor environment.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaqmmr-indoorairquality-pmlevels-activity-7236237299024338944-PMiL/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 17,
        image: image17,
        title: "Indoor Air Quality Analysis: Bank in Matheran – Part 2",
        description: "Investigating CO2 levels in indoor spaces of a bank.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaqmmr-indoorairquality-co2-activity-7239143876895137793-At4F/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 18,
        image: image18,
        title: "Indoor Air Quality Analysis of a Bank in Matheran – Part 3",
        description: "An overview of Carbon Monoxide (CO) presence in indoor environments.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaqmmr-indoorairquality-co-activity-7242028406131859456-9f6p/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 19,
        image: image19,
        title: "Indoor Air Quality analysis of a bank in Matheran – Part 4",
        description: "Evaluating TVOCs in indoor air as part of the IAQ study.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaqmmr-indoorairquality-tvocs-activity-7243849489910824960-JkoI/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 20,
        image: image20,
        title: "Indoor Air Quality Analysis of a Residence in Matheran – Part 2: CO2",
        description: "Assessing the indoor air quality of a residence with a focus on CO2 levels.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_tsl-safeiaqmmr-indoorairquality-activity-7249283376438779909-9Uhg/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 21,
        image: image21,
        title: "Indoor Air Quality Analysis of a Residence in Matheran – Part 2: CO2",
        description: "Detailed analysis of CO2 levels in a residential indoor environment.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_tsl-safeiaqmmr-indoorairquality-activity-7249283376438779909-9Uhg/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 22,
        image: image22,
        title: "Indoor Air Quality Analysis of a Residence in Matheran",
        description: "Comprehensive indoor air quality evaluation of a residence in Matheran.",
        link: " https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_tsl-safeiaqmmr-indoorairquality-activity-7246384842014130176-Qo2q/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 23,
        image: image23,
        title: "Indoor Air Quality Analysis of a Residence in Matheran – Part 3",
        description: "Investigating Carbon Monoxide (CO) levels in residential indoor spaces.",
        link: " https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaqmmr-indoorairquality-co-activity-7256894389690511362-zUPH/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 24,
        image: image24,
        title: "Indoor Air Quality Analysis of a Residence in Matheran – Part 2: CO2",
        description: "In-depth evaluation of CO2 levels in a residential indoor environment.",
        link: " https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_tsl-safeiaqmmr-indoorairquality-activity-7249283376438779909-9Uhg/?utm_source=share&utm_medium=member_desktop"
    },
    {
        id: 25,
        image: image25,
        title: " Indoor Air Quality Analysis: AC Restaurant in Matheran – Part 1: Particulate Matter (PM)",
        description: "Assessing TVOCs levels in an AC restaurant environment.",
        link: "https://www.linkedin.com/posts/techknowgreen-solutions-limited-global_safeiaqmmr-indoorairquality-health-activity-7234791570259009536-fPCw/?utm_source=share&utm_medium=member_desktop"
    }
    
    
    // Add remaining blogs in similar format
];


const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
    }
    return chunks;
};

const BlogPage = () => {
    const navigate = useNavigate();

    const handleClose = () => {
        navigate('/');
    };

    const blogsPerSlide = 3; // Number of blogs per carousel item
    const blogChunks = chunkArray(blogs, blogsPerSlide);

    return (
        <div className="blog-page">
            {/* <h1 className="blog-page-title">Our Blogs</h1> */}
            <div className="blog-container">
                {blogs.map((blog) => (
                    <div key={blog.id} className="blog-card">
                        <img src={blog.image} alt={blog.title} className="blog-image" />
                        <h2 className="blog-title">{blog.title}</h2>
                        <p className="blog-description">{blog.description}</p>
                        <a 
                            href={blog.link} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className="blog-link"
                        >
                            Read More
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogPage;