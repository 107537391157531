import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import '../../css/common.css';

const ResidentialQues = () => {
  const [observation, setObservation] = useState({
    observationId: null,
    participantId: '',
    locationId: '',
    observerName: '',
    observationDate: '',
    residentialAddress: '',
    buildingAge: '',
    newRenovation: '',
    totalArea: '',
    totalOccupants: '',
    numberOfRooms: '',
    cookingActivity: '',
    cookingFuel: '',
    cookingTimes: '',
    otherCookingTimes: '',
    kitchenVentilation: '',
    otherKitchenVentilation: '',
    indoorVentilation: '',
    naturalVentilationDetails: '',
    ductsMaintenance: '',
    numberOfVents: '',
    inletAirVelocity: null,
    outletAirVelocity: null,
    cleaningMethod: '',
    manualCleaningFrequency: '',
    equipmentCleaningFrequency: '',
    otherEquipmentCleaningFrequency:'',
    chemicalBasedCleaning: '',
    chemicalsUsed: '',
    residentialSamplingRoomArea: '',
    samplingRoomOccupants: '',
    furnitureType: '',
    numberOfTablesChairs: '',
    numberOfSofasBeds: '',
    furnitureMaterial: '',
    conditionOfWoodFurniture: '',
    woodDamageType: '',
    otherWoodDamageType: '',
    wallType: '',
    floorType: '',
    separateVentilationControl: '',
    moistureOrDampness: '',
    incenseProducts: '',
    incenseType: '',
    otherIncenseType: '',
    temperatureHumidityControl: '',
    lackOfMaintenance: '',
    roomFloorLevel: '',
    otherRoomFloorLevel: '',
    numberOfWindows: '',
    curtainsPresent: '',
    distanceFromTrafficSource: '',
    distanceFromKitchen: '',
    otherDistanceFromKitchen: '',
    airPurifiersUsed: '',
    electronicsUsed: '',
    petsPresent: '',
    smokingActivities: '',
    indoorPlantsPresent: '',
    numberOfPlants: '',
    otherNoOfPlants:'',
    otherObservations: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setObservation(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make POST request with observation data
      // const response = await axios.post('http://localhost:8081/residentials/add', observation);
      const response = await axios.post('http://89.116.20.162:8081/residentials/add', observation);

      console.log(response.data);
      // Reset observation state after successful submission
      setObservation({
        observationId: null,
        participantId: '',
        locationId: '',
        observerName: '',
        observationDate: '',
        residentialAddress: '',
        buildingAge: '',
        newRenovation: '',
        totalArea: '',
        totalOccupants: '',
        numberOfRooms: '',
        cookingActivity: '',
        cookingFuel: '',
        cookingTimes: '',
        otherCookingTimes: '',
        kitchenVentilation: '',
        otherKitchenVentilation: '',
        indoorVentilation: '',
        naturalVentilationDetails: '',
        ductsMaintenance: '',
        numberOfVents: '',
        inletAirVelocity: null,
        outletAirVelocity: null,
        cleaningMethod: '',
        manualCleaningFrequency: '',
        equipmentCleaningFrequency: '',
        otherEquipmentCleaningFrequency:'',
        chemicalBasedCleaning: '',
        chemicalsUsed: '',
        residentialSamplingRoomArea: '',
        samplingRoomOccupants: '',
        furnitureType: '',
        numberOfTablesChairs: '',
        numberOfSofasBeds: '',
        furnitureMaterial: '',
        conditionOfWoodFurniture: '',
        woodDamageType: '',
        otherWoodDamageType: '',
        wallType: '',
        otherWallType: '',
        floorType: '',
        otherFloorType: '',
        separateVentilationControl: '',
        moistureOrDampness: '',
        incenseProducts: '',
        incenseType: '',
        otherIncenseType: '',
        temperatureHumidityControl: '',
        lackOfMaintenance: '',
        roomFloorLevel: '',
        otherRoomFloorLevel: '',
        numberOfWindows: '',
        curtainsPresent: '',
        distanceFromTrafficSource: '',
        distanceFromKitchen: '',
        otherDistanceFromKitchen: '',
        airPurifiersUsed: '',
        electronicsUsed: '',
        petsPresent: '',
        smokingActivities: '',
        indoorPlantsPresent: '',
        numberOfPlants: '',
        otherNumberOfPlants:'',
        otherObservations: ''
      });
      alert('Observation submitted successfully!');
    } catch (error) {
      console.error('Error submitting observation:', error);
      alert('An error occurred while submitting the observation. Please try again.');
    }
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <center><h1 style={{ marginTop: "80px" }}>Residential</h1></center>
        <label>
          Participant ID:
          <input
            type="text"
            name="participantId"
            value={observation.participantId}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Location ID:
          <input
            type="text"
            name="locationId"
            value={observation.locationId}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Name of Observer:
          <input
            type="text"
            name="observerName"
            value={observation.observerName}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Observation Date:
          <input
            type="date"
            name="observationDate"
            value={observation.observationDate}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Residential Address:
          <input
            type="text"
            name="residentialAddress"
            value={observation.residentialAddress}
            onChange={handleChange}
            required
          />
        </label>
        <label>
  Building Age:
  <select
    name="buildingAge"
    value={observation.buildingAge}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="<5">&lt;5</option>
    <option value="5 to 10">5 to 10</option>
    <option value="10 to 15">10 to 15</option>
    <option value=">20">&gt;20</option>
  </select>
</label>
<label>
  New Renovation(less than 3 Months):
  <select
    name="newRenovation"
    value={observation.newRenovation}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>
<label>
  Total Area:
  <select
    name="totalArea"
    value={observation.totalArea}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="below 200m2">Below 200m2</option>
    <option value="200 to 500m2">200 to 500m2</option>
    <option value="500m2 to 1000m2">500m2 to 1000m2</option>
    <option value="more than 1000m2">More than 1000m2</option>
  </select>
</label>
<label>
  Total Occupants:
  <select
    name="totalOccupants"
    value={observation.totalOccupants}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="> 3">&gt; 3</option>
    <option value="3 to 5">3 to 5</option>
    <option value="5 to 10">5 to 10</option>
    <option value=">10">&gt; 10</option>
  </select>
</label>
<label>
  Number of Rooms:
  <select
    name="numberOfRooms"
    value={observation.numberOfRooms}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="1 to 2">1 to 2</option>
    <option value="3 to 5">3 to 5</option>
    <option value="5 to 10">5 to 10</option>
    <option value="more than 10">More than 10</option>
  </select>
</label>

<label>
  Cooking Activity:
  <select
    name="cookingActivity"
    value={observation.cookingActivity}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{observation.cookingActivity === "yes" && (
  <>
    <label>
      Type of Cooking Fuel:
      <select
        name="cookingFuel"
        value={observation.cookingFuel}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="wood">Wood</option>
        <option value="LPG">LPG</option>
        <option value="electric">Electric</option>
        <option value="coal">Coal</option>
        <option value="others">Others</option>
      </select>
    </label>

    <label>
      Cooking Times:
      <select
        name="cookingTimes"
        value={observation.cookingTimes}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="7-8 am">7-8 am</option>
        <option value="8-9 am">8-9 am</option>
        <option value="9-10 am">9-10 am</option>
        <option value="10-11 am">10-11 am</option>
        <option value="other">Other</option>
      </select>
    </label>

    {observation.cookingTimes === "other" && (
      <label>
        Other Cooking Times:
        <input
          type="text"
          name="otherCookingTimes"
          value={observation.otherCookingTimes}
          onChange={handleChange}
          required
        />
      </label>
    )}

    <label>
      Kitchen Ventilation:
      <select
        name="kitchenVentilation"
        value={observation.kitchenVentilation}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="window">Window</option>
        <option value="exhaust">Exhaust</option>
        <option value="chimney">Chimney</option>
        <option value="no ventilation">No Ventilation</option>
        <option value="other">Other</option>
      </select>
    </label>

    {observation.kitchenVentilation === "other" && (
      <label>
        Other Kitchen Ventilation:
        <input
          type="text"
          name="otherKitchenVentilation"
          value={observation.otherKitchenVentilation}
          onChange={handleChange}
          required
        />
      </label>
    )}
  </>
)}

<label>
  Ventilation of the whole residential indoor space  
    <select
    name="indoorVentilation"
    value={observation.indoorVentilation}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="natural">Natural</option>
    <option value="artificial">Artificial</option>
  </select>
</label>

{observation.indoorVentilation === "natural" && (
  <>
    <label>
      Natural Ventilation Details:
      <select
        name="naturalVentilationDetails"
        value={observation.naturalVentilationDetails}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="1 to 2">1 to 2</option>
        <option value="2 to 4">2 to 4</option>
        <option value="4 to 6">4 to 6</option>
        <option value=">6">More than 6</option>
      </select>
    </label>
  </>
)}

{observation.indoorVentilation === "artificial" && (
  <>
    <label>
      Ducts Maintenance:
      <select
        name="ductsMaintenance"
        value={observation.ductsMaintenance}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="<3 months">less than months</option>
        <option value="once a year">Once a year</option>
        <option value="2 to 3 years">2 to 3 years</option>
        <option value="none">None</option>
      </select>
    </label>
    <label>
  Number of Vents:
  <select
    name="numberOfVents"
    value={observation.numberOfVents}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="0 to 5">0 to 5</option>
    <option value="5 to 10">5 to 10</option>
    <option value="10 to 20">10 to 20</option>
    <option value="40 to 50">40 to 50</option>
  </select>
</label>

<label>
  Inlet Air Velocity:
  <input
    type="number"
    name="inletAirVelocity"
    value={observation.inletAirVelocity}
    onChange={handleChange}
    required
  />
</label>

<label>
  Outlet Air Velocity:
  <input
    type="number"
    name="outletAirVelocity"
    value={observation.outletAirVelocity}
    onChange={handleChange}
    required
  />
</label>
  </>
)}



<label>
  Cleaning Method:
  <select
    name="cleaningMethod"
    value={observation.cleaningMethod}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="manually">Manually</option>
    <option value="by equipment">By Equipment</option>
  </select>
</label>

<label>
  Manual Cleaning Frequency:
  <select
    name="manualCleaningFrequency"
    value={observation.manualCleaningFrequency}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="1 to 2">1 to 2</option>
    <option value="2 to 3">2 to 3</option>
    <option value="3 to 4">3 to 4</option>
    <option value="none">None</option>
  </select>
</label>

<label>
  Equipment Cleaning Frequency:
  <select
    name="equipmentCleaningFrequency"
    value={observation.equipmentCleaningFrequency}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="1 to 2">1 to 2</option>
    <option value="2 to 3">2 to 3</option>
    <option value="one weekly">One weekly</option>
    <option value="twice weekly">Twice weekly</option>
    <option value="others">Others</option>
  </select>
</label>

{observation.equipmentCleaningFrequency === "others" && (
  <label>
    Other Equipment Cleaning Frequency:
    <input
      type="text"
      name="otherEquipmentCleaningFrequency"
      value={observation.otherEquipmentCleaningFrequency}
      onChange={handleChange}
    />
  </label>
)}

<label>
  Chemical Based Cleaning:
  <select
    name="chemicalBasedCleaning"
    value={observation.chemicalBasedCleaning}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{observation.chemicalBasedCleaning === "yes" && (
  <label>
  Chemicals Used:
  <select
    name="chemicalsUsed"
    value={observation.chemicalsUsed}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="detergent">Detergent</option>
    <option value="phenyl">Phenyl</option>
    <option value="bleaching agents">Bleaching Agents</option>
    <option value="floor cleaners">Floor Cleaners</option>
    <option value="others">Others</option>
  </select>
</label>

)}

<label>
  Residential Sampling Room Area in meter sq.(m2):
  <select
    name="residentialSamplingRoomArea"
    value={observation.residentialSamplingRoomArea}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="100 to 150">100 to 150 m²</option>
    <option value="150 to 200">150 to 200 m²</option>
    <option value="200 to 250">200 to 250 m²</option>
    <option value="more than 300">More than 300 m²</option>
  </select>
</label>

<label>
  Residential Sampling Room Occupants:
  <select
    name="samplingRoomOccupants"
    value={observation.samplingRoomOccupants}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="<3">Less than 3</option>
    <option value="3 to 5">3 to 5</option>
    <option value="5 to 10">5 to 10</option>
    <option value=">10">More than 10</option>
  </select>
</label>

        {/* <label>
          Furniture Type:
          <input
            type="text"
            name="furnitureType"
            value={observation.furnitureType}
            onChange={handleChange}
            required
          />
        </label> */}
<label>
  Number of Tables Chairs:
  <select
    name="numberOfTablesChairs"
    value={observation.numberOfTablesChairs}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="2 to 4">2 to 4</option>
    <option value="4 to 6">4 to 6</option>
    <option value="6 to 8">6 to 8</option>
    <option value="8 to 10">8 to 10</option>
    <option value="other">Other</option>
  </select>
</label>

{observation.numberOfTablesChairs === 'other' && (
  <label>
    Other Number of Tables Chairs:
    <input
      type="text"
      name="otherNumberOfTablesChairs"
      value={observation.otherNumberOfTablesChairs}
      onChange={handleChange}
      required
    />
  </label>
)}



<label>
  Number of Sofas Beds:
  <select
    name="numberOfSofasBeds"
    value={observation.numberOfSofasBeds}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="2 to 4">2 to 4</option>
    <option value="4 to 6">4 to 6</option>
    <option value="6 to 8">6 to 8</option>
    <option value="8 to 10">8 to 10</option>
    <option value="other">Other</option>
  </select>
</label>

{observation.numberOfSofasBeds === 'other' && (
  <label>
    Other Number of Sofas Beds:
    <input
      type="text"
      name="otherNumberOfSofasBeds"
      value={observation.otherNumberOfSofasBeds}
      onChange={handleChange}
      required
    />
  </label>
)}


<label>
  Furniture Material:
  <select
    name="furnitureMaterial"
    value={observation.furnitureMaterial}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="plastics">Plastics</option>
    <option value="wood">Wood</option>
    <option value="metals">Metals</option>
    <option value="polished wood">Polished Wood</option>
    <option value="others">Others</option>
  </select>
</label>

<label>
  Condition of Wood Furniture:
  <select
    name="conditionOfWoodFurniture"
    value={observation.conditionOfWoodFurniture}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="good">Good</option>
    <option value="bad">Bad</option>
  </select>
</label>

{observation.conditionOfWoodFurniture === 'bad' && (
  <div>
    <label>
      Wood Damage Type:
      <select
        name="woodDamageType"
        value={observation.woodDamageType}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="Broken">Broken</option>
        <option value="damaged by termites">Damaged by Termites</option>
        <option value="mold patch present">Mold Patch Present</option>
        <option value="paint layer removal itself">Paint Layer Removal Itself</option>
        <option value="other">Other</option>
      </select>
    </label>
    {observation.woodDamageType === 'other' && (
      <label>
        Other Wood Damage Type:
        <input
          type="text"
          name="otherWoodDamageType"
          value={observation.otherWoodDamageType}
          onChange={handleChange}
          required
        />
      </label>
    )}
  </div>
)}

<label>
  Wall Type:
  <select
    name="wallType"
    value={observation.wallType}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="normal paint">Normal Paint</option>
    <option value="tiles">Tiles</option>
    <option value="PVC paneling">PVC Paneling</option>
    <option value="wood paneling">Wood Paneling</option>
    <option value="others">Others</option>
  </select>
</label>
{observation.wallType === "others" && (
  <label>
    Other Wall Type:
    <input
      type="text"
      name="otherWallType"
      value={observation.otherWallType}
      onChange={handleChange}
      required
    />
  </label>
)}

<label>
  Floor Type:
  <select
    name="floorType"
    value={observation.floorType}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="normal cemented">Normal Cemented</option>
    <option value="tiles">Tiles</option>
    <option value="carpet">Carpet</option>
    <option value="marble">Marble</option>
    <option value="others">Others</option>
  </select>
</label>
{observation.floorType === "others" && (
  <label>
    Other Floor Type:
    <input
      type="text"
      name="otherFloorType"
      value={observation.otherFloorType}
      onChange={handleChange}
      required
    />
  </label>
)}




<label>
	Any separate ventilation system control system for rooms..?
  <select
    name="separateVentilationControl"
    value={observation.separateVentilationControl}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
 Moisture or dampness patches/mark:
  <select
    name="moistureOrDampness"
    value={observation.moistureOrDampness}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
 Are there use of incense products..? 
  <select
    name="incenseProducts"
    value={observation.incenseProducts}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{observation.incenseProducts === 'yes' && (
  <div>
    <label>
      Incense Type:
      <select
        name="incenseType"
        value={observation.incenseType}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="Incense sticks and dhoops">Incense sticks and dhoops</option>
        <option value="perfumes and room fresheners">Perfumes and room fresheners</option>
        <option value="mosquito repellents and coils">Mosquito repellents and coils</option>
        <option value="other">Other</option>
      </select>
    </label>

    {observation.incenseType === 'other' && (
      <label>
        Other Incense Type:
        <input
          type="text"
          name="otherIncenseType"
          value={observation.otherIncenseType}
          onChange={handleChange}
          required
        />
      </label>
    )}
  </div>
)}

<label>
Any separate specific temperature or humidity control system for rooms..?
  <select
    name="temperatureHumidityControl"
    value={observation.temperatureHumidityControl}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
  Lack of Maintenance:
  <select
    name="lackOfMaintenance"
    value={observation.lackOfMaintenance}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
  Room Floor Level:
  <select
    name="roomFloorLevel"
    value={observation.roomFloorLevel}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="1">1</option>
    <option value="2">2</option>
    <option value="3">3</option>
    <option value="4">4</option>
    <option value="5">5</option>
    <option value="other">Other</option>
  </select>
</label>

{observation.roomFloorLevel === "other" && (
  <label>
    Other Room Floor Level:
    <input
      type="text"
      name="otherRoomFloorLevel"
      value={observation.otherRoomFloorLevel}
      onChange={handleChange}
      required
    />
  </label>
)}

        <label>
          Number of Windows:
          <input
            type="number"
            name="numberOfWindows"
            value={observation.numberOfWindows}
            onChange={handleChange}
            required
          />
        </label>
        <label>
  Curtains Present:
  <select
    name="curtainsPresent"
    value={observation.curtainsPresent}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
	Distance of room from traffic source in meters..?
  <select
    name="distanceFromTrafficSource"
    value={observation.distanceFromTrafficSource}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="100 to 200">100 to 200 meters</option>
    <option value="200 to 300">200 to 300 meters</option>
    <option value="300 to 400">300 to 400 meters</option>
    <option value="400 to 500">400 to 500 meters</option>
    <option value=">500">More than 500 meters</option>
  </select>
</label>
<label>
	Distance of rooms from kitchen in meters..?
  <select
    name="distanceFromKitchen"
    value={observation.distanceFromKitchen}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="1 to 2">1 to 2 meters</option>
    <option value="2 to 3">2 to 3 meters</option>
    <option value="3 to 4">3 to 4 meters</option>
    <option value="4 to 5">4 to 5 meters</option>
    <option value="other">Other</option>
  </select>
</label>

{observation.distanceFromKitchen === 'other' && (
  <label>
    Other Distance From Kitchen:
    <input
      type="text"
      name="otherDistanceFromKitchen"
      value={observation.otherDistanceFromKitchen}
      onChange={handleChange}
      required
    />
  </label>
)}

<label>
  Any type of air purifiers or filters used..?
  <select
    name="airPurifiersUsed"
    value={observation.airPurifiersUsed}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
Is there any use of laptop/computer/printer/scanners in indoor space..?
  <select
    name="electronicsUsed"
    value={observation.electronicsUsed}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
Is there any pet present in indoor space..?
  <select
    name="petsPresent"
    value={observation.petsPresent}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
	Are there any smoking related activities in the indoor space..?
  <select
    name="smokingActivities"
    value={observation.smokingActivities}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

<label>
  Any Indoor Plants Present in room..?:
  <select
    name="indoorPlantsPresent"
    value={observation.indoorPlantsPresent}
    onChange={handleChange}
    required
  >
    <option value="">Select</option>
    <option value="yes">Yes</option>
    <option value="no">No</option>
  </select>
</label>

{observation.indoorPlantsPresent === "yes" && (
  <div>
    <label>
      Number of Plants:
      <select
        name="numberOfPlants"
        value={observation.numberOfPlants}
        onChange={handleChange}
        required
      >
        <option value="">Select</option>
        <option value="1 to 3">1 to 3</option>
        <option value="3 to 5">3 to 5</option>
        <option value="5 to 7">5 to 7</option>
        <option value="7 to 9">7 to 9</option>
        <option value="other">Other</option>
      </select>
    </label>
    {observation.numberOfPlants === "other" && (
      <label>
        Other Number of Plants:
        <input
          type="number"
          name="otherNumberOfPlants"
          value={observation.otherNumberOfPlants}
          onChange={handleChange}
          required
        />
      </label>
    )}
  </div>
)}
         <label>
         Any other activities observed during sampling..?
          <input
            type="text"
            name="otherObservations"
            value={observation.otherObservations}
            onChange={handleChange}
            required
          />
        </label>

        
        <button type="submit">Submit</button>
      </form>
    </>
  );
};

export default ResidentialQues;
