import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import dayjs from "dayjs";
import "../../css/Landusewise.css";

// Register Chart.js components

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Locationwise = ({ region, season }) => {
  const [category, setCategory] = useState("");
  const [criteria, setCriteria] = useState("");
  const [data, setData] = useState(null);

  const parameterConfig = {
    co: { name: "CO", color: "#FF6384", dataKey: "co" },
    co2: { name: "CO2", color: "#4bc0c0", dataKey: "co2" },
    vocTigerxt: { name: "VOC", color: "#FF9F40", dataKey: "voc" },
    temperature: {
      name: "Temperature",
      color: "#4BC0C0",
      dataKey: "temperature",
    },
    relativeHumidity: {
      name: "Humidity",
      color: "#36a2eb",
      dataKey: "relativeHumidity",
    },
    windVelocity: {
      name: "Wind Velocity",
      color: "#9966FF",
      dataKey: "windVelocity",
    },
    grimms: {
      name: "PM",
      color: "#ff0000",
      subParams: [
        { key: "pm1", name: "PM1", color: "#FF6384" },
        { key: "pm2_5", name: "PM2.5", color: "#36A2EB" },
        { key: "pm10", name: "PM10", color: "#4BC0C0" },
      ],
    },
  };

  const categoryOptions = [
    { value: "offices", label: "Offices" },
    { value: "restaurantandeateries", label: "Restaurants" },
    { value: "cinemahall", label: "Cinema halls" },
    { value: "residences", label: "Residential Areas" },
    { value: "shoppingmall", label: "Malls" },
    { value: "school", label: "School" },
  ];

  const criteriaOptions = {
    offices: [
      { value: "ambientconditions", label: "AC" },
      { value: "presentnearindustrialorhighexposure", label: "Non-AC" },
      { value: "adjacenttotraffichotspot", label: "Near traffic hotspot" },
    ],
    school: [
      { value: "ambientconditions", label: "AC" },
      { value: "presentnearindustrialorhighexposure", label: "Non-AC" },
      { value: "adjacenttotraffichotspot", label: "Near traffic hotspot" },
    ],
    residences: [
      { value: "ambientconditions", label: "AC" },
      { value: "presentnearindustrialorhighexposure", label: "Non-AC" },
      { value: "adjacenttotraffichotspot", label: "Near traffic hotspot" },
    ],
    restaurantandeateries: [
      { value: "ambientconditions", label: "AC" },
      { value: "presentnearindustrialorhighexposure", label: "Non-AC" },
      { value: "adjacenttotraffichotspot", label: "Near traffic hotspot" },
    ],
    cinemahall: [
      { value: "ambientconditions", label: "AC" },
      { value: "presentnearindustrialorhighexposure", label: "Non-AC" },
      { value: "adjacenttotraffichotspot", label: "Near traffic hotspot" },
    ],
    shoppingmall: [
      { value: "ambientconditions", label: "AC" },
      { value: "presentnearindustrialorhighexposure", label: "Non-AC" },
      { value: "adjacenttotraffichotspot", label: "Near traffic hotspot" },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      if (region && season && category && criteria) {
        try {
          const response = await fetch(
            // `http://192.168.1.57:8081/api/combined-data/all?region=${region}&season=${season}&category=${category}&criteria=${criteria}`
            `http://89.116.20.162:8081/api/combined-data/all?region=${region}&season=${season}&category=${category}&criteria=${criteria}`

          );
          if (!response.ok) throw new Error("Failed to fetch data");
          const result = await response.json();
          setData(result);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
  }, [region, season, category, criteria]);

  const prepareChartData = (param) => {
    if (!data) return null;

    const config = parameterConfig[param];
    let chartData = {
      labels: [],
      datasets: []
    };

    if (param !== "grimms") {
      if (data[param]) {
        const sortedData = [...data[param]].sort((a, b) => 
          new Date(a.dateTime) - new Date(b.dateTime)
        );

        chartData.labels = sortedData.map(item => 
          dayjs(item.dateTime).format("YYYY-MM-DD")
        );

        chartData.datasets = [{
          label: config.name,
          data: sortedData.map(item => item[config.dataKey] || 0),
          borderColor: config.color,
          backgroundColor: `${config.color}33`,
          tension: 0.1,
          pointRadius: 4,
          pointHoverRadius: 8
        }];
      }
    } else {
      if (data.grimms) {
        const sortedData = [...data.grimms].sort((a, b) => 
          new Date(a.dateTime) - new Date(b.dateTime)
        );

        chartData.labels = sortedData.map(item => 
          dayjs(item.dateTime).format("YYYY-MM-DD")
        );

        chartData.datasets = config.subParams.map(subParam => ({
          label: subParam.name,
          data: sortedData.map(item => item[subParam.key] || 0),
          borderColor: subParam.color,
          backgroundColor: `${subParam.color}33`,
          tension: 0.1,
          pointRadius: 4,
          pointHoverRadius: 8
        }));
      }
    }

    return chartData;
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          padding: 20,
          usePointStyle: true
        }
      },
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
          title: (items) => {
            if (!items.length) return "";
            return `Date: ${items[0].label}`;
          },
          label: (item) => {
            return `${item.dataset.label}: ${item.formattedValue}`;
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
        grid: {
          display: false,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
        ticks: {
          maxRotation: 45,
          minRotation: 45,
          callback: () => "",   // Ensures the formatted date-time is shown as a label
        }
      },
      y: {
        grid: {
          drawBorder: false,
          drawTicks: true,
        },
        ticks: {
          padding: 5
        }
      }
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false
    }
  };
  
  return (
    <div className="contaaiiner">
      <div className="filters">
        <div className="filter">
          <label>Category:</label>
          <select
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
              setCriteria("");
            }}
            className="select"
          >
            <option value="">Select Category</option>
            {categoryOptions.map((cat) => (
              <option key={cat.value} value={cat.value}>
                {cat.label}
              </option>
            ))}
          </select>
        </div>
        <div className="filter">
          <label>Criteria:</label>
          <select
            value={criteria}
            onChange={(e) => setCriteria(e.target.value)}
            disabled={!category}
            className="select"
          >
            <option value="">Select Criteria</option>
            {category &&
              criteriaOptions[category]?.map((crit) => (
                <option key={crit.value} value={crit.value}>
                  {crit.label}
                </option>
              ))}
          </select>
        </div>
      </div>

      {(!category || !criteria) && (
        <div className="message">
          Please select a category and criteria to view data
        </div>
      )}

      {data && category && criteria && (
        <div className="chart-contaaiiner">
          {Object.keys(parameterConfig).map((param) => {
            const chartData = prepareChartData(param);
            if (!chartData || !chartData.datasets.length) return null;
            
            return (
              <div key={param} className="chart">
                {/* <h3>{parameterConfig[param].name}</h3> */}
                <div style={{ height: "300px" }}>
                  <Line
                    data={chartData}
                    options={{
                      ...chartOptions,
                      plugins: {
                        ...chartOptions.plugins,
                        title: {
                          display: false,
                          text: parameterConfig[param].name
                        }
                      }
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Locationwise;